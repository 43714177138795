"use client";

import SWRConfig from "@/components/ClientSWRConfig";
import { __DEV__ } from "@/lib/constants";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import br from "@/styles/breakpoints.module.css";
import { now } from "@/utils/dateTimeUtils";
import { StableSWRKeys } from "@knowt/syncing/hooks/swr/swr";
import { maybeLogSource } from "@knowt/syncing/hooks/user/auth";
import { LocalUser } from "@knowt/syncing/hooks/user/types";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import Skeleton from "@mui/material/Skeleton";
import dynamic from "next/dynamic";
import React, { Suspense, useEffect } from "react";
import { MaybeShowError } from "./MaybeShowError";
import NavbarSearchbar from "./components/NavbarSearchBar";
import { themeColors } from "@/utils/themeColors";
import styles from "./navbar.module.css";
import { useCheckToDisplayInitialAccountSetupPage } from "../InitialSetupPage/InitialSetupPage";
import { NavbarLink } from "./components/NavbarLink";
import KnowtLogo from "../Sidebar/components/KnowtLogo";
import noop from "@knowt/syncing/utils/noop";
import { AccountType } from "@knowt/syncing/graphql/schema";

const NavbarHamburgerMenu = dynamic(() => import("./components/NavbarHamburgerMenu"));
const StreakMetricWithDetailsPopup = dynamic(
    () => import("./components/gamification/metrics/StreakMetricWithDetailsPopup")
);
const LevelMetricWithDetailsPopup = dynamic(
    () => import("./components/gamification/metrics/LevelMetricWithDetailsPopup")
);
const CoinsMetricWithDetailsPopup = dynamic(
    () => import("./components/gamification/metrics/CoinsMetricWithDetailsPopup")
);
const NavBarBanner = dynamic(() => import("./components/NavbarBanner"));
const NavbarSigninBtn = dynamic(() => import("./components/styled/NavbarAuthButtons").then(m => m.NavbarSigninBtn));
const NavbarSignupBtn = dynamic(() => import("./components/styled/NavbarAuthButtons").then(m => m.NavbarSignupBtn));
const NavbarCreateContentBtn = dynamic(() => import("./components/NavbarCreateContentBtn"));

type NavBarProps = {
    serverUser?: LocalUser;
    innerContainerStyle?: React.CSSProperties;
    outerContainerStyle?: React.CSSProperties;
    className?: string;
    landing?: boolean;
};

const NavBarAuthSkeleton = () => {
    return (
        <FlexRowAlignCenter style={{ gap: "2rem" }}>
            <Skeleton animation="wave" width={"8rem"} height={30} />
            <Skeleton animation="wave" width={"14rem"} height={30} />
        </FlexRowAlignCenter>
    );
};

// If we make this a server component, it will always force dynamic rendering, which is not what we want
const NavBar = ({ serverUser, innerContainerStyle, outerContainerStyle, className, landing = false }: NavBarProps) => {
    useCheckToDisplayInitialAccountSetupPage({ fallbackData: serverUser });

    const { user, loginInProgress } = useCurrentUser({
        fallbackData: serverUser,
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: only log the source if the user has been made within the last minute
    useEffect(() => {
        if (serverUser?.user && now() - (serverUser.user?.created || 0) < 120) {
            maybeLogSource(serverUser.user);
        }
    }, []);

    const renderLeftContent = () => (
        <FlexRowAlignCenter key="left-content" style={{ flex: 1, gap: "1rem" }}>
            {user && <NavbarHamburgerMenu className={br.smUpDisplayNone} />}
            {!user && (
                <FlexRowAlignCenter
                    key={"navbar-links"}
                    style={{ gap: "2rem", marginLeft: "1rem" }}
                    className={br.mdDownDisplayNone}>
                    <KnowtLogo onClick={noop} isExpanded={false} />
                    <NavbarLink key="home" text="Home" href="/" />
                    <NavbarLink key="explore" text="Explore" href="/explore" />
                    <NavbarLink key="exams" text="Exams" href="/exams" />
                </FlexRowAlignCenter>
            )}
            <NavbarSearchbar landing={landing} />
        </FlexRowAlignCenter>
    );

    const renderRightContent = () => {
        const rightItems: React.ReactElement<any>[] = [];
        // TODO: show a cleaner way to show the loading/maybe add animation
        if (loginInProgress) {
            rightItems.push(<NavBarAuthSkeleton key={"navbar-skeleton"} />);
        } else {
            if (user) {
                rightItems.push(<NavbarCreateContentBtn key="create-new-content-btn" />);
                if (user.accountType === AccountType.Student) {
                    rightItems.push(
                        <StreakMetricWithDetailsPopup user={user} key="streak-metric-with-details-popup" />
                    );
                }
                rightItems.push(
                    <div className={br.mdDownDisplayNone} key="level-metric-with-details-popup">
                        <LevelMetricWithDetailsPopup user={user} key="level-metric-with-details-popup" />
                    </div>
                );
                rightItems.push(<CoinsMetricWithDetailsPopup user={user} key="coins-metric-with-details-popup" />);
            } else {
                rightItems.push(
                    <NavbarSigninBtn key="signin-btn" landing={landing} />,
                    <NavbarSignupBtn key="signup-btn" />
                );
            }
        }

        return (
            <FlexRowAlignCenter style={{ justifyContent: "safe flex-end", columnGap: "1rem" }}>
                {rightItems}
            </FlexRowAlignCenter>
        );
    };

    return (
        <SWRConfig
            value={{
                fallback: {
                    [StableSWRKeys.USER]: serverUser,
                },
            }}>
            <FlexColumn
                as="nav"
                className={className}
                id={"navbar"}
                style={{ position: "sticky", top: "0", zIndex: 898, ...outerContainerStyle }}>
                <FlexRowAlignCenter
                    key="inner-container"
                    className={styles.smDownNavInnerSidePadding}
                    style={{
                        paddingInline: user ? "4rem" : "2rem",
                        position: "relative",
                        marginTop: "-1px",
                        justifyContent: "space-between",
                        gap: "2rem",
                        transition: "box-shadow ease 0.5s",
                        height: "var(--navbar-height)",
                        backgroundColor: landing ? themeColors.neutralWhite : themeColors.background,
                        ...innerContainerStyle,
                    }}>
                    {renderLeftContent()}
                    {renderRightContent()}
                </FlexRowAlignCenter>
                <div className={br.smDownDisplayNone}>
                    <NavBarBanner />
                </div>
            </FlexColumn>
            <Suspense>
                <MaybeShowError />
            </Suspense>
            {__DEV__ && <div style={{ zIndex: 999, position: "fixed", top: "7.5rem" }}>{user?.ID || "no user..."}</div>}
            {/* <LeaderboardATFMobile /> */}
        </SWRConfig>
    );
};

export default NavBar;
