"use client";
import { ConditionalLink } from "@/components/ConditionalLink";

type NavbarLinkProps = {
    href?: string;
    text: string;
    className?: string;
    forceDarkTheme?: boolean;
};

export const NavbarLink = ({ href, text }: NavbarLinkProps) => {
    return (
        <ConditionalLink
            href={href as string}
            condition={!!href}
            style={{
                fontWeight: "600",
                fontSize: "1.5rem",
            }}>
            {text}
        </ConditionalLink>
    );
};

export default NavbarLink;
