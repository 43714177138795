"use client";

import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { ASSETS_URL } from "@/config/deployConstants";
import { borderRadius } from "@/utils/borderRadius";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import GUIDED_EVENTS, { AllEventNames } from "@knowt/syncing/hooks/guidedEvents/guidedEvents";
import useGuidedEvent from "@knowt/syncing/hooks/guidedEvents/useGuidedEvent";
import { getPlanName } from "@knowt/syncing/hooks/user/subscriptionConstants";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { Check, X } from "lucide-react";
import Image from "next/image";
import InitialSetupContainer from "../styled/IntialSetupContainer";

const features = [
    {
        isChecked: true,
        text: "No ads",
    },
    {
        isChecked: true,
        text: "Unlimited explanations on incorrect answers during Learn Mode",
    },
    {
        isChecked: true,
        text: "Customization & password protecting",
    },
];

const OrganizationUpgradedAccountStep = () => {
    const { subscriptionType } = useCurrentUser();

    const { closeEvent } = useGuidedEvent(GUIDED_EVENTS.INITIAL_SETUP);

    const handleOnNext = async () => {
        await closeEvent(AllEventNames.ORG_UPGRADED_ACCOUNT);

        window.location.href = "/";
    };

    return (
        <InitialSetupContainer style={{ gap: spacing.LG_2 }}>
            <FlexColumn
                style={{
                    backgroundColor: themeColors.neutralWhite,
                    borderRadius: borderRadius.card,
                    gap: "4rem",
                }}>
                <Image src={`${ASSETS_URL}/images/crown.svg`} alt="Crown icon" width={124} height={124} />
                <FlexColumn>
                    <h1 className="heading4">
                        You’re on the {getPlanName(subscriptionType)} plan! Press continue so we can link the
                        subscription to your account.
                    </h1>
                    <p className="body2">
                        Your school is awesome -- they already bought you the plus plan and now you’ll be able to do
                        some pretty cool things.
                    </p>
                </FlexColumn>

                <FlexColumn
                    style={{
                        border: `solid 1px ${themeColors.neutralBlack}`,
                        padding: spacing.SM,
                        borderRadius: borderRadius.card,
                        width: "fit-content",
                        gap: spacing.XS_2,
                        maxWidth: "80%",
                    }}>
                    {features.map((feature, index) => (
                        <FlexRowAlignCenter key={index} style={{ gap: spacing.XS_2 }}>
                            <FlexColumnAlignJustifyCenter
                                style={{
                                    backgroundColor: feature.isChecked
                                        ? themeColors.neutralBlack
                                        : themeColors.errorPrimary,
                                    width: "fit-content",
                                    borderRadius: "50%",
                                    padding: spacing.XXS,
                                }}>
                                {feature.isChecked ? (
                                    <Check size={24} color={themeColors.neutralWhite} />
                                ) : (
                                    <X size={24} color={themeColors.neutralWhite} />
                                )}
                            </FlexColumnAlignJustifyCenter>
                            <span className="body2">{feature.text}</span>
                        </FlexRowAlignCenter>
                    ))}
                </FlexColumn>

                <FlexColumnAlignJustifyCenter style={{ alignItems: "flex-end" }}>
                    <CircularRectTextButton
                        onClick={handleOnNext}
                        style={{
                            backgroundColor: themeColors.primary,
                            color: themeColors.primaryDark,
                            padding: `${spacing.XS_2} ${spacing.MD}`,
                        }}>
                        {"Continue"}
                    </CircularRectTextButton>
                </FlexColumnAlignJustifyCenter>
            </FlexColumn>
        </InitialSetupContainer>
    );
};

export default OrganizationUpgradedAccountStep;
