import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import InitialSetupContainer from "../styled/IntialSetupContainer";
import { themeColors } from "@/utils/themeColors";
import RepeatAutoFillGrid from "@/components/styled/div/RepeatAutoFillGrid";
import { ASSETS_URL } from "@/config/deployConstants";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import Image from "next/image";
import Radio from "@mui/material/Radio";
import { useState } from "react";
import InitialSetupProceedBtn from "../styled/InitialSetupProceedBtn";
import { updateUserInfo } from "@knowt/syncing/hooks/user/utils";
import Mixpanel from "@/utils/analytics/Mixpanel";
import dynamic from "next/dynamic";
import LazyLoaded from "@/components/LazyLoaded";
import br from "@/styles/breakpoints.module.css";
import { clsx } from "clsx";
import Circular from "@/components/Circular";
import { Check } from "lucide-react";
import { strokeWidth } from "@/utils/iconProps";
import toast from "react-hot-toast";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import CircularButton from "@/components/CircularButton";

const EnterReferralPopup = dynamic(() => import("./EnterReferralPopup"));

const OptionCard = ({
    isCheck,
    onClick,
    children,
    style,
}: {
    isCheck: boolean;
    onClick: () => void;
    children: React.ReactNode;
    style?: React.CSSProperties;
}) => {
    return (
        <CircularButton
            onClick={onClick}
            className="bodyBold2"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                textAlign: "left",
                padding: "1rem 1.4rem",
                border: `1px solid ${themeColors.neutral1}`,
                backgroundColor: themeColors.neutralWhite,
                cursor: "pointer",
                borderRadius: "1rem",
                gap: "1.1rem",
                ...style,
            }}>
            <Radio
                disableTouchRipple
                disableRipple
                checked={isCheck}
                style={{ margin: "-1.5rem" }}
                sx={{
                    color: themeColors.neutral1,
                    "&.Mui-checked": {
                        color: themeColors.neutralBlack,
                    },
                    "& .MuiSvgIcon-root": {
                        fontSize: "3rem",
                    },
                    width: "fit-content",
                }}
            />
            {children}
        </CircularButton>
    );
};

const AddReferralPill = () => {
    const { user } = useCurrentUser();
    const [isReferralPopupOpen, setIsReferralPopupOpen] = useState(false);
    const [isAccepted, setIsAccepted] = useState(!!user?.refFrom_v2);

    if (isAccepted) {
        return (
            <FlexRowAlignCenter
                className={br.smDownTextAlignCenter}
                style={{
                    padding: "1rem",
                    borderRadius: "1.5rem",
                    gap: "1rem",
                    width: "100%",
                    minHeight: "4.4rem",
                    backgroundColor: themeColors.successSecondary,
                }}>
                <Circular radius={"2.4rem"} style={{ backgroundColor: themeColors.primary }}>
                    <Check color={themeColors.primaryDark} strokeWidth={strokeWidth.bold} size="1.7rem" />
                </Circular>
                <p className="secondaryTextBold1">Your referral code was accepted!</p>
            </FlexRowAlignCenter>
        );
    }

    const onAccept = () => setIsReferralPopupOpen(true);

    return (
        <>
            <FlexRowAlignCenter
                className={clsx(br.smDownDisplayNone)}
                style={{
                    padding: "0.8rem 1.6rem",
                    gap: "1rem",
                    borderRadius: "7.4rem",
                    width: "100%",
                    minHeight: "5.6rem",
                    backgroundColor: themeColors.flashcardsLight,
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                }}>
                <p className="secondaryTextBold1">Did your friend give you a referral code?</p>
                <CircularRectTextButton
                    onClick={onAccept}
                    className={clsx("secondaryTextBold1")}
                    sx={{
                        height: "4rem",
                        width: "16rem",
                        backgroundColor: themeColors.primary3,
                        color: themeColors.pureBlack,
                    }}>
                    Yes, let me enter it
                </CircularRectTextButton>
            </FlexRowAlignCenter>

            <FlexColumn
                className={clsx(br.smUpDisplayNone)}
                style={{
                    padding: "1.6rem",
                    gap: "1rem",
                    borderRadius: "2rem",
                    width: "100%",
                    minHeight: "5.6rem",
                    backgroundColor: themeColors.flashcardsLight,
                    flexWrap: "wrap",
                    alignItems: "center",
                }}>
                <p className="secondaryTextBold1">Did your friend give you a referral code?</p>
                <CircularRectTextButton
                    onClick={onAccept}
                    className={clsx("secondaryTextBold1")}
                    sx={{
                        height: "4rem",
                        width: "100%",
                        backgroundColor: themeColors.primary3,
                        color: themeColors.pureBlack,
                    }}>
                    Yes, let me enter it
                </CircularRectTextButton>
            </FlexColumn>
            <LazyLoaded load={isReferralPopupOpen}>
                <EnterReferralPopup
                    isOpen={isReferralPopupOpen}
                    onClose={() => setIsReferralPopupOpen(false)}
                    onSubmit={async ({ code }) => {
                        try {
                            await updateUserInfo({ refFrom_v2: code });
                            setIsAccepted(true);
                            setIsReferralPopupOpen(false);
                            toast.success("Successfully apply your referral");
                        } catch {
                            toast.error("This referral code is not valid. Please try again.");
                        }
                    }}
                />
            </LazyLoaded>
        </>
    );
};

const HowDidYouHearAndWannaUse = ({ onNext }: { onNext: () => void }) => {
    const [hearFrom, setHearFrom] = useState<string>("");
    const [wantToUseFor, setWantToUseFor] = useState<string>("");

    const isHearFromOther = hearFrom.includes("Other");
    const isWantToUseForOther = wantToUseFor === "Other";

    const submitForm = async () => {
        await updateUserInfo({ referral: hearFrom });
        Mixpanel.track("User Feedback - Referral", { referral: hearFrom, goal: wantToUseFor });
        onNext();
    };

    return (
        <InitialSetupContainer>
            <h1 className="heading4" style={{ marginBottom: "3rem" }}>
                Let’s personalize your account
            </h1>

            <AddReferralPill />

            <FlexColumn style={{ gap: "1rem", margin: "3rem 0" }}>
                <p className="bodyBold1">How did you hear about Knowt?</p>
                <RepeatAutoFillGrid itemMinWidth="30rem" style={{ marginBottom: "1rem", gap: "1rem" }}>
                    {[
                        { app: "Tiktok", img: `/images/tiktok-icon-black.svg` },
                        { app: "Google", img: `/images/google-icon.svg` },
                        { app: "Instagram", img: `/images/instagram-icon.svg` },
                        { app: "A Friend", img: `/images/family-icon.svg` },
                        { app: "Youtube", img: `/images/yt-icon.svg` },
                        { app: "A Teacher", img: `/images/books.svg` },
                    ].map(({ app, img }, i) => {
                        return (
                            <OptionCard
                                isCheck={hearFrom.includes(app)}
                                key={i + app}
                                onClick={() => {
                                    setHearFrom(app);
                                }}>
                                <Image src={img} alt={app} height={22} width={22} />
                                {app}
                            </OptionCard>
                        );
                    })}
                </RepeatAutoFillGrid>
                <CircularRectTextButton
                    className="bodyBold2"
                    onClick={() => {
                        setHearFrom("Other");
                    }}
                    sx={{
                        width: "100%",
                        height: "4.6rem",
                        border: `1px solid ${themeColors.neutral1}`,
                        borderRadius: "1rem",
                        backgroundColor: isHearFromOther ? themeColors.neutral1 : themeColors.neutralWhite,
                    }}>
                    Other
                </CircularRectTextButton>
            </FlexColumn>
            <FlexColumn style={{ gap: "1rem", marginBottom: "3rem" }}>
                <p className="bodyBold1">What do you want to use on Knowt?</p>
                <RepeatAutoFillGrid itemMinWidth="18rem" style={{ marginBottom: "1rem", gap: "1rem" }}>
                    {[
                        { tool: "AI Summarizer", img: `/images/ai-summarizer-icon.png` },
                        { tool: "Flashcards", img: `${ASSETS_URL}/images/flashcard-icon.svg` },
                        { tool: "Notes", img: `${ASSETS_URL}/images/note-icon.png` },
                    ].map(({ tool, img }, i) => {
                        return (
                            <OptionCard
                                isCheck={wantToUseFor === tool}
                                key={i + tool}
                                onClick={() => {
                                    setWantToUseFor(tool);
                                }}>
                                <Image src={img} alt={tool} height={22} width={22} />
                                {tool}
                            </OptionCard>
                        );
                    })}
                </RepeatAutoFillGrid>
                <CircularRectTextButton
                    onClick={() => setWantToUseFor("Other")}
                    className="bodyBold2"
                    sx={{
                        width: "100%",
                        height: "4.6rem",
                        border: `1px solid ${themeColors.neutral1}`,
                        backgroundColor: isWantToUseForOther ? themeColors.neutral1 : themeColors.neutralWhite,
                        borderRadius: "1rem",
                    }}>
                    Other
                </CircularRectTextButton>
            </FlexColumn>

            <InitialSetupProceedBtn onClick={submitForm} sx={{ marginLeft: "auto" }}>
                Next
            </InitialSetupProceedBtn>
        </InitialSetupContainer>
    );
};

export default HowDidYouHearAndWannaUse;
