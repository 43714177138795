import { SetState } from "@knowt/syncing/types/common";
import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";

/**
 * Global state made using swr
 */
export const useGlobalState = <T>(key: string | null, value: T) => {
    const { data: state, mutate } = useSWRImmutable("useGlobalState" + key, () => value, { fallbackData: value });

    const setState = useCallback(
        (newValue: T) => {
            mutate(newValue, { revalidate: false });
        },
        [mutate]
    );

    return [state, setState] as [T, SetState<T>];
};
