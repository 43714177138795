"use client";
import { Search, SearchIcon } from "lucide-react";
import { useState } from "react";
import NavbarCircularBtn from "./styled/NavbarCircularBtn";
import LazyLoaded from "@/components/LazyLoaded";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import dynamic from "next/dynamic";
import br from "@/styles/breakpoints.module.css";
import { SxProps } from "@mui/material/styles";
import { clsx } from "clsx";

const SearchPopup = dynamic(() => import("@/components/popupWithSidebar/SearchPopup"));

type NavbarSearchbarProps = {
    placeholder?: string;
    alwaysFullWidth?: boolean;
    sx?: SxProps;
    landing?: boolean;
};

const NavbarSearchbar = ({ placeholder, sx, landing = false }: NavbarSearchbarProps) => {
    const [isSearchPopupOpen, setIsSearchPopupOpen] = useState(false);

    return (
        <>
            <NavbarCircularBtn
                id="main-search-bar"
                key="mobile-search"
                className={br.mdUpDisplayNone}
                onClick={() => setIsSearchPopupOpen(true)}
                sx={{ borderColor: landing ? themeColors.background : themeColors.neutralWhite }}>
                <Search size={iconSizes.SM} strokeWidth={strokeWidth.normal} />
            </NavbarCircularBtn>

            <CircularRectTextButton
                key={"desktop-search-bar"}
                className={clsx("ellipsisText", br.mdDownDisplayNone)}
                sx={{
                    flex: 1,
                    height: "4rem",
                    borderRadius: "50rem",
                    backgroundColor: landing ? themeColors.background : themeColors.neutralWhite,
                    "&:hover": {
                        boxShadow: "none",
                    },
                    fontSize: "1.4rem",
                    gap: "1rem",
                    padding: "0rem 2rem",
                    justifyContent: "flex-start",
                    ...sx,
                }}
                onClick={() => setIsSearchPopupOpen(true)}>
                <SearchIcon size={iconSizes.MD} style={{ opacity: "0.5" }} />
                <div className="ellipsisText" style={{ opacity: "0.5" }}>
                    {placeholder || "Search for anything"}
                </div>
            </CircularRectTextButton>

            <LazyLoaded load={isSearchPopupOpen}>
                <SearchPopup isOpen={isSearchPopupOpen} onClose={() => setIsSearchPopupOpen(false)} />
            </LazyLoaded>
        </>
    );
};

export default NavbarSearchbar;
