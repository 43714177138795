import { Address, VerifiedSchool, VerifiedSchoolInput } from "@knowt/syncing/graphql/schema";
import { runESQueryFull } from "../../fetchFunctions/elasticsearch";
import { capitalize } from "@/utils/stringUtils";

export const fetchSchools = async (schoolIds: string[], queryFields = ["schoolId"]) => {
    if (!schoolIds.length) return null;

    return (
        await runESQueryFull<VerifiedSchool>({
            queryFields,
            queryPhrase: schoolIds,
            returnFields: [
                "name",
                "schoolId",
                "country",
                "state",
                "address.city",
                "address.zip",
                "gradesLow",
                "gradesHigh",
                "org",
                "verified",
            ],
            searchIndex: ["VERIFIED_SCHOOL"],
            exactMatch: true,
            pagesize: 50,
        })
    )?.items;
};

export const fetchSchoolsById = async (schoolIds: string[]) => {
    return await fetchSchools(schoolIds, ["schoolId"]);
};

export const getSchoolIdsByName = async (schoolNames: string[]) => {
    const school = await fetchSchools(schoolNames, ["name"]);
    return school?.[0]?.schoolId;
};

export const isValidPhoneNumber = (phone: string) => {
    if (!phone) return false;

    // USA Check
    const phoneRegexUS = /^\d{3}-\d{3}-\d{4}$/;
    const usNumber = phoneRegexUS.test(phone);

    // International Check
    const phoneRegexIntl = /^\d{1,4}-\d{1,4}-\d{4}$/;
    const intlNumber = phoneRegexIntl.test(phone);

    return usNumber || intlNumber;
};

export const cleanAddress = (address: Omit<Address, "__typename">) => {
    return {
        country: address.country.trim(),
        state: address.state.trim(),
        city: capitalize(address.city.trim()),
        zip: address.zip.trim(),
        streetOne: capitalize(address.streetOne.trim()),
        streetTwo: capitalize(address.streetTwo.trim()),
    };
};

export const validateSchoolField = (schoolData: Omit<VerifiedSchoolInput, "schoolId" | "updated">) => {
    if (!schoolData.country.trim()) {
        throw new Error("Please select a country");
    }

    if (!schoolData.state.trim()) {
        throw new Error("Please select a state");
    }

    if (!schoolData.address.city.trim()) {
        throw new Error("Please enter a city");
    }

    if (!schoolData.address.zip.trim()) {
        throw new Error("Please enter a zip code");
    }

    if (!schoolData.name.trim()) {
        throw new Error("Please enter a name");
    }

    if (!schoolData.address.streetOne.trim()) {
        throw new Error("Please enter an address");
    }

    if (!isValidPhoneNumber(schoolData.phone)) {
        throw new Error("Please enter a valid phone number");
    }

    if (!schoolData.gradesLow || !schoolData.gradesHigh) {
        throw new Error("Please provide a grade range");
    }
};
