import CircularButton, { CircularButtonProps } from "@/components/CircularButton";
import { preventStopPropogation } from "@/utils/domUtils";
import { strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import { LucideIcon, LucideProps } from "lucide-react";
import dynamic from "next/dynamic";

const LoadingAnimation = dynamic(() => import("@/components/LoadingAnimation"), { ssr: false });

export type CircularFilledIconProps = {
    isLoading?: boolean;
    Icon: LucideIcon;
    IconProps?: Partial<LucideProps>;
    primaryBg?: string;
    secondaryBg?: string;
    primaryColor?: string;
    secondaryColor?: string;
} & CircularButtonProps;

export const CircularFilledIcon = ({
    ref,
    primaryBg = themeColors.neutralWhite,
    secondaryBg = themeColors.neutralBlack,
    primaryColor = themeColors.neutralBlack,
    secondaryColor = themeColors.neutralWhite,
    isLoading = false,
    Icon,
    IconProps,
    radius = "4rem",
    onClick,
    sx,
    ...btnProps
}: CircularFilledIconProps & {
    ref?: React.RefObject<unknown>;
}) => {
    // default icon styles
    const { style: iconStyle, size, ...iconProps } = { strokeWidth: strokeWidth.normal, ...IconProps };

    return (
        <CircularButton
            ref={ref}
            radius={radius}
            onClick={e => {
                preventStopPropogation(e);
                onClick?.(e);
            }}
            sx={{
                // transition: "background-color 0.2s ease, color 0.2s ease, transform 0.2s ease",
                backgroundColor: primaryBg,
                // icon styles
                "&>svg": {
                    width: size,
                    height: size,
                    color: primaryColor,
                    ...iconStyle,
                },
                "&:disabled": {
                    pointerEvents: "none",
                    opacity: "0.5",
                },
                "&:active": {
                    transform: "scale(0.99)",
                },
                "&:hover": {
                    backgroundColor: secondaryBg,
                    "& > svg": {
                        color: secondaryColor,
                    },
                },
                ...sx,
            }}
            {...btnProps}>
            {isLoading ? <LoadingAnimation size={24} /> : <Icon {...iconProps} />}
        </CircularButton>
    );
};

CircularFilledIcon.displayName = "CircularFilledIcon";
export default CircularFilledIcon;
