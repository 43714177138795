"use client";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import Box from "@mui/material/Box";

const SidebarIcon = ({ children, style, sx, ...divProps }: React.HTMLAttributes<HTMLDivElement> & { sx?: any }) => {
    return (
        <FlexColumnAlignJustifyCenter
            id="SidebarIcon"
            as={Box}
            sx={{
                "& > svg": {
                    color: themeColors.neutralBlack,
                },
                width: "3.2rem",
                height: "3.2rem",
                padding: "0.6rem",
                borderRadius: "0.5rem",
                fontSize: "1.2rem",
                ...sx,
            }}
            {...divProps}>
            {children}
        </FlexColumnAlignJustifyCenter>
    );
};

export default SidebarIcon;
