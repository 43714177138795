import CircularFilledIcon from "@/components/CircularButton/styled/CircularFilledIcon";
import BorderedInputDatePicker from "@/components/DatePicker/styled/BorderedInputDatePicker";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import Mountable from "@/components/styled/div/Mountable";
import RoundInput from "@/components/styled/input/RoundInput";
import { ASSETS_URL } from "@/config/deployConstants";
import br from "@/styles/breakpoints.module.css";
import { themeColors } from "@/utils/themeColors";
import { AccountType, UserDetails } from "@knowt/syncing/graphql/schema";
import { isUnderAge, updateUserInfo, updateUserUsername } from "@knowt/syncing/hooks/user/utils";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import Radio from "@mui/material/Radio";
import { Check } from "lucide-react";
import Image from "next/image";
import { useMemo } from "react";
import InitialSetupProceedBtn from "../styled/InitialSetupProceedBtn";
import InitialSetupContainer from "../styled/IntialSetupContainer";
import { validateBasicIdentity, validateUsername } from "@knowt/syncing/hooks/user/userVerificationUtils";
import toast from "react-hot-toast";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useDebounce } from "react-use";
import { callIsUsernameAvailable } from "@knowt/syncing/hooks/user/graphqlUtils";
import CircularButton from "@/components/CircularButton";
import { signOut } from "@knowt/syncing/hooks/user/auth";
import { useRouter } from "@bprogress/next";

const checkUsernameAvailability = async ({ username }: { username: string }) => {
    try {
        validateUsername(username);
        const isAvailable = await callIsUsernameAvailable(username);

        if (!isAvailable) throw new Error("Sorry that username is already being used");

        return { isValid: true, message: "" };
    } catch (err) {
        return { isValid: false, message: err.message };
    }
};

const AccountTypeCard = ({
    onClick,
    accountType,
    features,
    isCheck,
}: {
    onClick: () => void;
    accountType: string;
    features: string[];
    isCheck: boolean;
}) => {
    return (
        <CircularButton
            onClick={onClick}
            className={br.smDownWidth100}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                textAlign: "left",
                padding: "1rem 1.4rem",
                width: "24rem",
                height: "20rem",
                justifyContent: "center",
                border: `1px solid ${isCheck ? themeColors.neutralBlack : themeColors.neutral1}`,
                cursor: "pointer",
                borderRadius: "1rem",
            }}>
            <div style={{ marginBottom: "1.5rem" }}>
                <Radio
                    disableTouchRipple
                    disableRipple
                    checked={isCheck}
                    style={{ margin: "-1.5rem" }}
                    sx={{
                        color: themeColors.neutral1,
                        "&.Mui-checked": {
                            color: themeColors.neutralBlack,
                        },
                        "& .MuiSvgIcon-root": {
                            fontSize: "3rem",
                        },
                        width: "fit-content",
                    }}
                />
            </div>
            <p className="bodyBold2" style={{ marginBottom: "1.5rem" }}>
                {accountType}
            </p>
            <FlexColumn style={{ gap: "1.5rem" }}>
                {features.map(f => {
                    return (
                        <FlexRowAlignCenter key={f} style={{ gap: "0.7rem" }}>
                            <CircularFilledIcon
                                component={"div"}
                                Icon={Check}
                                disabled
                                radius={"2.3rem"}
                                IconProps={{ strokeWidth: 4, size: "1.4rem" }}
                                primaryBg={themeColors.neutralBlack}
                                primaryColor={themeColors.neutralWhite}
                                sx={{
                                    "&:disabled": {
                                        opacity: "1",
                                    },
                                }}
                            />
                            <p className="tertiaryText1">{f}</p>
                        </FlexRowAlignCenter>
                    );
                })}
            </FlexColumn>
        </CircularButton>
    );
};

const BasicIdentity = ({ onNext }: { onNext: ({ user }: { user: UserDetails }) => void }) => {
    const router = useRouter();
    const { user, isSocialSignIn } = useCurrentUser();
    const [profileForm, updateProfileForm] = useCombinedState(() => ({
        username: user?.username || "",
        birthday: user?.birthday ? new Date(user.birthday) : null,
        accountType: user?.accountType || AccountType.Student,
        name: user?.Name || "",
        refFrom_v2: user?.refFrom_v2 as string | undefined,
    }));

    const username = profileForm.username;

    const [usernameAvailability, updateUsernameAvailability] = useCombinedState(() => ({
        isValid: true,
        message: "",
    }));

    const saveUserInfo = async () => {
        if (!usernameAvailability.isValid) return toast.error(usernameAvailability.message);

        try {
            const { username, name, birthday, accountType, refFrom_v2 } = profileForm;
            validateBasicIdentity({ username, name, birthday, accountType });

            if (isUnderAge(birthday, 13) && !isSocialSignIn) {
                await signOut();
                setTimeout(() => router.push("/block?reason=under13"), 1000);
                return;
            }

            await updateUserUsername(username.toLowerCase());
            const updatedUser = await updateUserInfo({
                birthday: birthday?.toISOString()?.slice(0, 10),
                accountType,
                Name: name,
                // only pass refFrom_v2 if it's not already set
                ...(!user?.refFrom_v2 ? { refFrom_v2 } : {}),
            });
            onNext({ user: { ...updatedUser, username: username.toLowerCase() } });
        } catch (err) {
            const toastMessage = err?.message;

            toast.error(toastMessage);
        }
    };

    useDebounce(
        () => {
            if (!username) updateUsernameAvailability({ isValid: true, message: "" });
            else checkUsernameAvailability({ username }).then(res => updateUsernameAvailability(res));
        },
        180,
        [username]
    );

    const isUnder13 = useMemo(() => isUnderAge(profileForm.birthday, 13), [profileForm.birthday]);

    return (
        <InitialSetupContainer>
            <h1 className="heading4" style={{ marginBottom: "3rem" }}>
                Let’s personalize your account
            </h1>

            <FlexColumn style={{ gap: "1rem", marginBottom: "3rem" }}>
                <p className="bodyBold2">What’s your name?</p>
                <RoundInput
                    style={{ height: "4.8rem", width: "100%" }}
                    value={profileForm.name}
                    onChange={e => updateProfileForm({ name: e.target.value })}
                />
            </FlexColumn>

            <FlexRowAlignCenter
                style={{ gap: "1rem", marginBottom: "3rem", justifyContent: "space-between", flexWrap: "wrap" }}>
                <p className="bodyBold2">Birthday</p>
                <BorderedInputDatePicker
                    placeholder="Pick your date of birth"
                    disabled={isUnder13}
                    value={profileForm.birthday}
                    DatePickerProps={{
                        disableFuture: true,
                    }}
                    style={{ width: "22.8rem", borderColor: themeColors.neutral1 }}
                    onChange={newDate => updateProfileForm({ birthday: newDate.toDate() })}
                />
            </FlexRowAlignCenter>

            <FlexColumn style={{ gap: "1rem", marginBottom: "3rem" }}>
                <FlexColumn>
                    <p className="bodyBold2" style={{ marginBottom: "0.5rem" }}>
                        Set a username
                    </p>
                    {usernameAvailability.isValid ? (
                        <p className="secondaryText1">Pick a username. You can change this later.</p>
                    ) : (
                        <p className="secondaryText1" style={{ color: themeColors.errorPrimary }}>
                            {usernameAvailability.message}
                        </p>
                    )}
                </FlexColumn>
                <RoundInput
                    style={{ height: "4.8rem", width: "100%" }}
                    value={profileForm.username}
                    onChange={e => updateProfileForm({ username: e.target.value })}
                />
            </FlexColumn>

            <FlexColumn style={{ gap: "1rem", marginBottom: "3rem" }}>
                <p className="bodyBold2">What type of account do you want?</p>
                <Flex className={br.smDownColumn} style={{ gap: "2rem" }}>
                    <AccountTypeCard
                        isCheck={profileForm.accountType === AccountType.Student}
                        onClick={() => updateProfileForm({ accountType: AccountType.Student })}
                        accountType={AccountType.Student}
                        features={["Exclusive AI tools just for students", "Scan & solve AI"]}
                    />
                    <AccountTypeCard
                        isCheck={profileForm.accountType === AccountType.Teacher}
                        onClick={() => updateProfileForm({ accountType: AccountType.Teacher })}
                        accountType={AccountType.Teacher}
                        features={["AI Tools built for teachers", "Create classes"]}
                    />
                </Flex>
            </FlexColumn>

            <Mountable mount={profileForm.accountType === AccountType.Teacher}>
                <FlexRowAlignCenter
                    className="secondaryTextBold1"
                    style={{
                        backgroundColor: themeColors.videoLight,
                        borderRadius: "1.2rem",
                        padding: "1.6rem",
                        gap: "1.2rem",
                        marginBottom: "3rem",
                    }}>
                    <Image src={`${ASSETS_URL}/images/triangle-alert-icon.svg`} alt="alert" width={24} height={24} />
                    You will not be able to switch account types after, and you will be missing some features designed
                    specifically for students
                </FlexRowAlignCenter>
            </Mountable>

            <InitialSetupProceedBtn onClick={saveUserInfo} sx={{ marginLeft: "auto" }}>
                Next
            </InitialSetupProceedBtn>
        </InitialSetupContainer>
    );
};

export default BasicIdentity;
