import { MigrateAccountContentInput } from "@/graphql/schema";
import { client } from "@/utils/client/graphql";
import { migrateAccountContent } from "@/graphql/mutations";
import { scrapeEmptyFields } from "@/utils/dataCleaning";

export const callMigrateAccountContent = async ({ stage, userId, commit, migKey }: MigrateAccountContentInput) => {
    return await client
        .mutate({
            mutation: migrateAccountContent,
            variables: {
                input: scrapeEmptyFields({
                    stage,
                    userId,
                    commit,
                    migKey,
                }),
            },
        })
        .then(({ data }) => data.migrateAccountContent)
        .catch(() => {
            throw new Error("Unable to migrate user account");
        });
};
