"use client";

import { styled } from "@mui/material/styles";

const SidebarSection = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    transition: transform 200ms ease-out;

`;

export default SidebarSection;
