import { themeColors } from "@/utils/themeColors";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import { User } from "lucide-react";

type UserAvatarProps = {
    src?: string | undefined;
    radius: string;
    alt?: string | null | undefined;
} & Omit<AvatarProps, "src" | "alt">;

/**
 * MUI Avatar wrapper but with custom alt following what our app needs
 */
const UserAvatar = ({ src, radius, alt: defaultAlt, sx, ...avatarProps }: UserAvatarProps) => {
    const alt = (defaultAlt || "U")
        .split(" ")
        .map(s => s[0])
        .join("")
        .slice(0, 2)
        .toUpperCase();

    return (
        <Avatar
            sx={{
                width: radius,
                height: radius,
                fontSize: `calc(${radius} / 2.5)`,
                backgroundColor: themeColors.background,
                ...sx,
            }}
            alt={alt ?? "U"}
            src={src}
            {...avatarProps}>
            <User size={`calc(${radius} / 1.6)`} color={themeColors.neutralBlack} />
        </Avatar>
    );
};

export default UserAvatar;
