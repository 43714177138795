"use client";

import Dialog from "@mui/material/Dialog";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker, StaticDatePickerProps } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { themeColors } from "@/utils/themeColors";
import { ModalProps } from "@mui/material/Modal";
import KnowtMUIThemeProvider from "../KnowtMUIThemeProvider";

type DateInputProps = {
    defaultValue: dayjs.Dayjs;
    isOpen: boolean;
    onClose: ModalProps["onClose"];
} & StaticDatePickerProps<dayjs.Dayjs>;

/**
 * Headless Date Picker. This component is only the picker with no `input` being bounded so we have full control on how we want
 * to style the input when it's combined
 * This has interesting props like:
 * - `disablePast`
 * - `disableFuture`
 * etc. https://mui.com/x/api/date-pickers/static-date-picker/#props
 */
const DatePicker = ({ sx, defaultValue, isOpen, onClose, ...staticDatePickerProps }: DateInputProps) => {
    const { smDown } = useBreakPoints();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog
                slotProps={{ root: { style: { zIndex: 1400 } } }}
                open={isOpen}
                onClose={(e, reason) => {
                    if (reason === "backdropClick") return;
                    onClose?.(e, reason);
                }}>
                <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <KnowtMUIThemeProvider>
                        <StaticDatePicker
                            {...staticDatePickerProps}
                            onClose={onClose}
                            orientation={smDown ? "portrait" : "landscape"}
                            defaultValue={defaultValue}
                            sx={{ fontSize: "3rem", color: themeColors.neutralBlack, ...sx }}
                        />
                    </KnowtMUIThemeProvider>
                </div>
            </Dialog>
        </LocalizationProvider>
    );
};

export default DatePicker;
