import { hide, show, update } from "@intercom/messenger-js-sdk";

export const showIntercom = () => {
    if (typeof window !== "undefined") {
        show();
    }
};

export const hideIntercom = () => {
    if (typeof window !== "undefined") {
        hide();
    }
};

export const removeIntercomWidget = () => {
    if (typeof window !== "undefined") {
        update({
            hide_default_launcher: true,
        });
    }
};

export const showIntercomWidget = () => {
    if (typeof window !== "undefined") {
        update({
            hide_default_launcher: false,
        });
    }
};
