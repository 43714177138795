import { ASSETS_URL } from "@/config/deployConstants";
import { themeColors } from "@/utils/themeColors";

export enum SidebarTab {
    HOME = "home",
    FILES = "files",
    SAVED = "saved",
    TRASH = "trash",
    CLASSES = "classes",
    ADMIN_DASHBOARD = "admin_dashboard",
    AI_TOOLS = "ai_tools",
    EXPLORE = "explore",
    EXAMS = "exams",
    STORE = "store",
    AI_PDF_SUMMARIZER = "ai_pdf_summarizer",
    AI_VIDEO_SUMMARIZER = "ai_video_summarizer",
    AI_LECTURE_NOTE_TAKER = "ai_lecture_note_taker",
    REFERRAL = "referral",
    SETTINGS = "settings",
    HELP = "help",
}

/** Get sidebar highlihgt's css (MUI sx) props */
export const getSidebarHighlight = ({
    username,
    pageTab,
    pathname,
    isExpanded,
}: {
    username: string;
    pageTab: SidebarTab | undefined;
    pathname: string;
    isExpanded: boolean;
}) => {
    let svgHighlightColor = themeColors.primaryLight;
    let shouldHighlight = false;

    if (!pageTab) return {};

    if (pageTab === SidebarTab.CLASSES && pathname === "/classes") {
        shouldHighlight = true;
    } else if (pageTab === SidebarTab.ADMIN_DASHBOARD && pathname.includes("/admin/org/")) {
        shouldHighlight = true;
    } else if (pageTab === SidebarTab.SAVED && pathname === "/saved") {
        shouldHighlight = true;
    } else if (pageTab === SidebarTab.TRASH && pathname === "/trash") {
        shouldHighlight = true;
    } else if (pageTab === SidebarTab.FILES && pathname === "/materials") {
        shouldHighlight = true;
    } else if (pageTab === SidebarTab.HOME && pathname === "/home") {
        shouldHighlight = true;
    } else if (pageTab === SidebarTab.AI_TOOLS && pathname.includes("/free-ai-tools-for-teachers")) {
        shouldHighlight = true;
    } else if (pageTab === SidebarTab.SETTINGS && pathname === "/settings") {
        shouldHighlight = true;
    } else if (pageTab === SidebarTab.EXPLORE && pathname === "/explore") {
        shouldHighlight = true;
    } else if (pageTab === SidebarTab.AI_PDF_SUMMARIZER && pathname === "/ai-pdf-summarizer") {
        shouldHighlight = true;
        svgHighlightColor = themeColors.neutralWhite;
    } else if (pageTab === SidebarTab.AI_VIDEO_SUMMARIZER && pathname === "/ai-video-summarizer") {
        shouldHighlight = true;
        svgHighlightColor = themeColors.neutralWhite;
    } else if (pageTab === SidebarTab.AI_LECTURE_NOTE_TAKER && pathname === "/ai-lecture-note-taker") {
        shouldHighlight = true;
        svgHighlightColor = themeColors.neutralWhite;
    } else if (pageTab === SidebarTab.EXAMS && pathname === "/exams") {
        shouldHighlight = true;
    } else if (pageTab === SidebarTab.STORE && pathname === "/store") {
        shouldHighlight = true;
    } else if (pageTab === SidebarTab.REFERRAL && pathname === `/u/${username}`) {
        shouldHighlight = true;
    }

    if (!shouldHighlight) return {};

    return {
        ...(isExpanded && {
            backgroundColor: themeColors.primaryLight,
            color: themeColors.pureBlack,
        }),
        "&:hover": {
            color: themeColors.pureBlack,
            outline: "none",
            boxShadow: "none",
        },
        // we use `&&` to override "& > svg" in `SidebarIcon`
        "&& #SidebarIcon": {
            backgroundColor: svgHighlightColor,
            svg: {
                color: themeColors.primaryDark,
            },
        },
    };
};

export const getSidebarTabHref = ({
    pageTab,
    username,
    org,
}: {
    pageTab: SidebarTab;
    username: string;
    org: string;
}) => {
    if (pageTab === SidebarTab.HOME) return "/home";
    if (pageTab === SidebarTab.FILES) return "/materials";
    if (pageTab === SidebarTab.CLASSES) return "/classes";
    if (pageTab === SidebarTab.ADMIN_DASHBOARD) return `/admin/org/${org}`;
    if (pageTab === SidebarTab.SAVED) return "/saved";
    if (pageTab === SidebarTab.TRASH) return "/trash";
    if (pageTab === SidebarTab.AI_TOOLS) return "/free-ai-tools-for-teachers";
    if (pageTab === SidebarTab.SETTINGS) return "/settings";
    if (pageTab === SidebarTab.EXPLORE) return "/explore";
    if (pageTab === SidebarTab.EXAMS) return "/exams";
    if (pageTab === SidebarTab.AI_PDF_SUMMARIZER) return "/ai-pdf-summarizer";
    if (pageTab === SidebarTab.AI_VIDEO_SUMMARIZER) return "/ai-video-summarizer";
    if (pageTab === SidebarTab.AI_LECTURE_NOTE_TAKER) return "/ai-lecture-note-taker";
    if (pageTab === SidebarTab.STORE) return "/store";
    if (pageTab === SidebarTab.REFERRAL) return `/u/${username}`;

    return "/home";
};

export const getSidebarImage = ({ tab }: { tab: SidebarTab }) => {
    if (tab === SidebarTab.AI_PDF_SUMMARIZER) {
        return `${ASSETS_URL}/images/pdf-icon.svg`;
    } else if (tab === SidebarTab.AI_VIDEO_SUMMARIZER) {
        return `${ASSETS_URL}/images/video-icon.svg`;
    } else if (tab === SidebarTab.AI_LECTURE_NOTE_TAKER) {
        return `${ASSETS_URL}/images/audio-icon.svg`;
    }

    return null;
};
