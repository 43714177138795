import { redirectAfterLoginIfNeeded } from "@/app/page/rootComponents/AuthHubListener";
import { useUpgradePopupContextSelector } from "@/features/Payments/PaywallPopup/UpgradePopupContext";
import { SubscriptionType, UpgradeEvent } from "@knowt/syncing/graphql/schema";
import GUIDED_EVENTS, { AllEventNames } from "@knowt/syncing/hooks/guidedEvents/guidedEvents";
import useGuidedEvent from "@knowt/syncing/hooks/guidedEvents/useGuidedEvent";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";

import { useEffect, useRef } from "react";

const PlanPromotion = () => {
    const { subscriptionType } = useCurrentUser();
    const openUpgradePopup = useUpgradePopupContextSelector(state => state.openUpgradePopup);

    const { closeEvent, stagedEvents } = useGuidedEvent(GUIDED_EVENTS.PLAN_PROMOTION);

    const opened = useRef(false);

    useEffect(() => {
        if (!opened.current && stagedEvents?.length) {
            opened.current = true;

            if (subscriptionType === SubscriptionType.BASIC) {
                openUpgradePopup({
                    event: UpgradeEvent.GENERIC,
                    isForPromotion: true,
                    context: {},
                    onClose: () => async () => {
                        await closeEvent(AllEventNames.STUDENT_TRIAL_2024);
                        redirectAfterLoginIfNeeded();
                    },
                    dialogProps: {
                        slotProps: {
                            backdrop: {
                                style: {
                                    background: "transparent",
                                },
                            },
                        },
                    },
                });
            } else {
                closeEvent(AllEventNames.STUDENT_TRIAL_2024).then(redirectAfterLoginIfNeeded);
            }
        }
    }, [closeEvent, openUpgradePopup, stagedEvents, subscriptionType]);

    return null;
};

export default PlanPromotion;
