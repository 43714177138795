"use client";

import { deepScrapeEmptyFields } from "@knowt/syncing/utils/dataCleaning";
import { SWRConfig as BaseSWRConfig, SWRConfiguration } from "swr";

const SWRConfig = ({ children, ...props }: React.ComponentProps<typeof BaseSWRConfig>) => {
    const fallback = deepScrapeEmptyFields((props.value as SWRConfiguration)?.fallback ?? {});

    return (
        <BaseSWRConfig
            {...props}
            value={{
                ...props.value,
                fallback,
            }}>
            {children}
        </BaseSWRConfig>
    );
};

export default SWRConfig;
