import { FlexRowAlignCenter } from "@/components/Flex";
import Image from "next/image";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { KNOWT_GAMIFICATION_STORAGE } from "@knowt/syncing/hooks/gamification/constants";
import { StoreProductEnum } from "@knowt/syncing/graphql/schema";

const KnowtLogo = ({ onClick, isExpanded, style }: { onClick: () => void; isExpanded: boolean; style?: any }) => {
    const { user } = useCurrentUser();

    const icon = user?.inventory?.icon
        ? `${KNOWT_GAMIFICATION_STORAGE}/store/${user?.inventory?.icon}.png`
        : `${KNOWT_GAMIFICATION_STORAGE}/store/${StoreProductEnum.ICON_DEFAULT}.png`;

    return (
        <FlexRowAlignCenter style={{ gap: "0.5rem", ...style }} onClick={onClick}>
            <Image src={icon} width={29} height={29} style={{ objectFit: "contain", borderRadius: 8 }} alt="knowt" />
            {isExpanded && (
                <Image
                    src={`/images/knowt-text.svg`}
                    alt="knowt"
                    width={79}
                    height={20}
                    style={{ marginTop: "0.2rem" }}
                />
            )}
        </FlexRowAlignCenter>
    );
};

export default KnowtLogo;
