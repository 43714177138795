import CircularRectTextButton, {
    CircularRectTextButtonProps,
} from "@/components/CircularButton/styled/CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";

const InitialSetupProceedBtn = ({ children, sx, ...props }: CircularRectTextButtonProps) => {
    return (
        <CircularRectTextButton
            className="bodyBold2"
            sx={{
                color: themeColors.primaryDark,
                backgroundColor: themeColors.primary,
                padding: `1.2rem 3.2rem`,
                position: "sticky",
                bottom: "2rem",
                ...sx,
            }}
            {...props}>
            {children}
        </CircularRectTextButton>
    );
};

export default InitialSetupProceedBtn;
