import CircularButton from "@/components/CircularButton";
import { themeColors } from "@/utils/themeColors";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { SxProps } from "@mui/material/styles";

const SidebarToggler = ({
    isVisible,
    isExpanded,
    onClick,
    sx,
}: {
    isVisible: boolean;
    isExpanded: boolean;
    onClick: () => void;
    sx?: SxProps;
}) => {
    const LucideArrow = isExpanded ? ChevronLeft : ChevronRight;

    return (
        <CircularButton
            radius="2.5rem"
            sx={{
                backgroundColor: themeColors.neutralWhite,
                border: `1px solid ${themeColors.neutral1}`,
                borderRadius: 1,
                padding: "0.5rem",
                "&:hover": {
                    backgroundColor: themeColors.neutralOverlay,
                    color: themeColors.neutralBlack,
                    boxShadow: "none",
                },
                opacity: isVisible ? 1 : 0,
                transition: "opacity 200ms ease-in-out, right 200ms ease-in-out",
                zIndex: 1000,
                ...sx,
            }}
            tooltip={!isExpanded ? "Expand Sidebar" : "Collapse Sidebar"}
            onClick={onClick}>
            <LucideArrow color={themeColors.neutralBlack} />
        </CircularButton>
    );
};

export default SidebarToggler;
