"use client";

import Image from "next/image";
import { Check, X } from "lucide-react";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { borderRadius } from "@/utils/borderRadius";
import { ASSETS_URL } from "@/config/deployConstants";
import useGuidedEvent from "@knowt/syncing/hooks/guidedEvents/useGuidedEvent";
import GUIDED_EVENTS, { AllEventNames } from "@knowt/syncing/hooks/guidedEvents/guidedEvents";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import InitialSetupContainer from "../styled/IntialSetupContainer";

const studentFeatures = [
    {
        isChecked: true,
        text: "No ads!",
    },
    {
        isChecked: false,
        text: "You can’t purchase subscriptions on your school account, only your school can (we’ll notify them when you run into a paid feature)",
    },
    {
        isChecked: false,
        text: "You can’t make flashcard sets or notes public to share with others outside your organization",
    },
    {
        isChecked: false,
        text: "Your profile page is not viewable to anyone outside your organization",
    },
];

const teacherFeatures = [
    {
        isChecked: true,
        text: "Students will not see ads, but will still see ads for Knowt services (compliant with your districts privacy policy).",
    },
    {
        isChecked: false,
        text: "Students can’t purchase subscriptions on their school account, only your school/district can for them. You have no restrictions on purchasing.",
    },
    {
        isChecked: false,
        text: "Student can’t make flashcard sets or notes public to share with others outside your organization.",
    },
    {
        isChecked: false,
        text: "Students don’t have a public profile visible to anyone outside the organization.",
    },
];

const OrganizationManagedAccountStep = () => {
    const { isTeacher } = useCurrentUser();

    const { closeEvent } = useGuidedEvent(GUIDED_EVENTS.INITIAL_SETUP);

    const handleOnNext = async () => {
        await closeEvent(AllEventNames.ORG_MANAGED_ACCOUNT);

        window.location.href = "/";
    };

    return (
        <InitialSetupContainer>
            <FlexColumn
                style={{
                    backgroundColor: themeColors.neutralWhite,
                    borderRadius: borderRadius.card,
                    gap: "4rem",
                }}>
                <Image src={`${ASSETS_URL}/images/school-icon.svg`} alt="Crown icon" width={124} height={124} />
                <FlexColumn>
                    <h1 className="heading4">
                        Sorry they didn’t purchase a plan, but your account is managed by the district now. Here’s what
                        that means for you.
                    </h1>
                    <p className="body2">
                        Knowt is working with your school to protect your privacy and this comes with some benefits and
                        restrictions that are placed by your school on your school account.
                    </p>
                </FlexColumn>

                <FlexColumn
                    style={{
                        border: `solid 1px ${themeColors.neutralBlack}`,
                        padding: spacing.SM,
                        borderRadius: borderRadius.card,
                        width: "fit-content",
                        gap: spacing.XS_2,
                        maxWidth: "80%",
                    }}>
                    {(isTeacher ? teacherFeatures : studentFeatures).map((feature, index) => (
                        <FlexRowAlignCenter key={index} style={{ gap: spacing.XS_2 }}>
                            <FlexColumnAlignJustifyCenter
                                style={{
                                    backgroundColor: feature.isChecked
                                        ? themeColors.neutralBlack
                                        : themeColors.errorPrimary,
                                    width: "fit-content",
                                    borderRadius: "50%",
                                    padding: spacing.XXS,
                                }}>
                                {feature.isChecked ? (
                                    <Check size={24} color={themeColors.neutralWhite} />
                                ) : (
                                    <X size={24} color={themeColors.neutralWhite} />
                                )}
                            </FlexColumnAlignJustifyCenter>
                            <span className="body2">{feature.text}</span>
                        </FlexRowAlignCenter>
                    ))}
                </FlexColumn>

                <FlexColumnAlignJustifyCenter style={{ alignItems: "flex-end" }}>
                    <CircularRectTextButton
                        onClick={handleOnNext}
                        style={{
                            backgroundColor: themeColors.primary,
                            color: themeColors.primaryDark,
                            padding: `${spacing.XS_2} ${spacing.MD}`,
                        }}>
                        {"Continue"}
                    </CircularRectTextButton>
                </FlexColumnAlignJustifyCenter>
            </FlexColumn>
        </InitialSetupContainer>
    );
};

export default OrganizationManagedAccountStep;
