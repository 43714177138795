"use client";

import { setRedirectAfterLogin } from "@/app/page/rootComponents/AuthHubListener";
import CircularRectTextButton, {
    CircularRectTextButtonProps,
} from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import { ASSETS_URL } from "@/config/deployConstants";
import { useAuthSlideContextSelector } from "@/features/Auth/AuthSlide/hook/useAuthSlideContext";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { Organization } from "@knowt/syncing/graphql/schema";
import { useStageSSOAccountLinking } from "@knowt/syncing/hooks/ssoAccountLinking/useSSOAccountLinking";
import Image from "next/image";
import toast from "react-hot-toast";
import InitialSetupContainer from "../styled/IntialSetupContainer";

const LinkButton = ({ ...props }: CircularRectTextButtonProps) => {
    return (
        <CircularRectTextButton
            style={{
                padding: `${spacing.XS_2} ${spacing.LG_2}`,
                border: `1px solid ${themeColors.neutralBlack}`,
                gap: "1rem",
                width: "100%",
                minHeight: "5.3rem",
            }}
            {...props}>
            {props.children}
        </CircularRectTextButton>
    );
};

const LinkSchoolEmailStep = ({ organization }: { organization: Organization | undefined }) => {
    const { linkInitialAccount } = useStageSSOAccountLinking();
    const openAuthSlide = useAuthSlideContextSelector(state => state.openAuthSlide);

    const _onSubmit = async () => {
        toast.loading("Just a moment...", { id: "signing-out" });
        openAuthSlide({ showSignupPage: true });
        setRedirectAfterLogin("/login");
        await linkInitialAccount();
        toast.success(`Please login with your school account to continue linking your accounts.`, {
            id: "signing-out",
        });
    };

    const getOrgAliasesList = () => {
        if (!organization) return [];

        return organization.aliases?.length
            ? `@${organization.org}, ${organization.aliases.map(alias => `@${alias}`).join(", ")}`
            : `@${organization.org}`;
    };

    return (
        <InitialSetupContainer style={{ alignItems: "center" }}>
            <Image
                src={`${ASSETS_URL}/images/school-icon.svg`}
                alt={"Search your school"}
                width={124}
                height={124}
                style={{ margin: "4rem auto 0" }}
            />
            <FlexColumnAlignJustifyCenter style={{ textAlign: "center", gap: spacing.MD, marginBottom: "4rem" }}>
                <h1 className="heading4">
                    Do you already have a Knowt account with any of these emails? {getOrgAliasesList()}
                </h1>
                <p className="body2">If you can’t remember just click No and we’ll set you up!</p>
            </FlexColumnAlignJustifyCenter>

            <FlexColumnAlignJustifyCenter style={{ gap: "1rem", margin: "auto" }}>
                <CircularRectTextButton
                    onClick={_onSubmit}
                    style={{
                        padding: "1.8rem 1.6rem",
                        fontSize: "1.5rem",
                        marginTop: spacing.MD,
                        backgroundColor: themeColors.primary,
                        color: themeColors.primaryDark,
                        width: "20rem",
                    }}>
                    Yes
                </CircularRectTextButton>
                <LinkButton onClick={_onSubmit}>No</LinkButton>
            </FlexColumnAlignJustifyCenter>
        </InitialSetupContainer>
    );
};

export default LinkSchoolEmailStep;
