import clsx from "clsx";
import { Rem } from "@/types/common";
import styles from "./container.module.css";
import { Flex, FlexColumn } from "@/components/Flex";
import React from "react";
import Sidebar from "@/features/Sidebar";
import NavBar from "@/features/NavBar/NavBar";
import { LocalUser } from "@knowt/syncing/hooks/user/types";

type StyleMustProps = {
    /** every page has different top and bottom padding, *it is a must* to pass the `margin-block` */
    marginBlock: `${Rem} ${Rem}`;
    /** every page has different side padding, *it is a must* to pass the `margin-inline` */
    marginInline: `${Rem} ${Rem}`;
} & React.CSSProperties;

/**
 * Our general main container width and it's max-width.
 * - This main container should be the first wrapper to all the layout inside it such: The content/InnerContainer and the ads.
 * - This container is just a normal `Flex` with some styles defining it's width, max-width, side's padding on desktop and mobile, and margin.
 * - Every page has different top and bottom padding, so *it is a must* to pass the `padding-block` through style.
 */
export const MainContainer = ({
    children,
    className,
    style,
    serverUser,
    noNavBar = false,
    noSidebar = false,
    forceCollapsedSidebar = false,
    footerAds = false,
    ...divProps
}: {
    style: StyleMustProps;
    serverUser?: LocalUser;
    noNavBar?: boolean;
    noSidebar?: boolean;
    forceCollapsedSidebar?: boolean;
    footerAds?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <Flex>
            {!noSidebar && (
                <Sidebar serverUser={serverUser} forceCollapsed={forceCollapsedSidebar} footerAds={footerAds} />
            )}
            <div
                style={{
                    flex: 1,
                    width: "50%", // I don't know why, but adding `width: 50%` fix the scrolling issue
                }}>
                {!noNavBar && <NavBar />}
                <Flex className={clsx(styles.mainContainer, className)} style={{ flex: 1, ...style }} {...divProps}>
                    {children}
                </Flex>
            </div>
        </Flex>
    );
};

/**
 * Our general content container
 * - Inner container should be the wrapper for main content "not including ads"
 * - This is just a `FlexColumn` with some style defining it's width, max-width, and force the content to stretch until it's maximum
 */
export const InnerContainer = ({ children, style, className, ...divProps }: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumn
            style={{ flex: 1, margin: "0 auto", ...style }}
            className={clsx(styles.innerContainerWidth, className)}
            {...divProps}>
            {children}
        </FlexColumn>
    );
};
