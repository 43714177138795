"use client";

import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import WithOptionsMenu, { WithMenuOption } from "@/components/WithOptionsMenu";
import UserAvatar from "@/components/userCardComponents/UserAvatar";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { signOut } from "@/hooks/user/auth";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { iconSizes } from "@/utils/iconProps";
import { useThemeContextSelector } from "@/utils/theme";
import { themeColors } from "@/utils/themeColors";
import { SubscriptionType } from "@knowt/syncing/graphql/schema";
import { canShowAds } from "@knowt/syncing/hooks/user/subscriptions";
import { FileText, GlobeLock, LogOut, Moon, Sparkles, User } from "lucide-react";
import { toast } from "react-hot-toast";

const ProfileCircleWithMenu = ({
    isSidebarExpanded,
    pictureUrl,
    radius,
    footerAds = false,
}: { isSidebarExpanded: boolean; pictureUrl?: string | null; radius?: string; footerAds?: boolean }) => {
    const isDarkMode = useThemeContextSelector(state => state.isDarkMode);
    const changeTheme = useThemeContextSelector(state => state.changeTheme);

    const { mdDown } = useBreakPoints({ md: 1100 });

    const { user, subscriptionType, isSubscriptionCancelling, resubscribe, organization } = useCurrentUser();

    const subscriptionOptionText = isSubscriptionCancelling
        ? "Resubscribe"
        : subscriptionType === SubscriptionType.BASIC
          ? "Remove Ads"
          : "Upgrade";

    const subscriptionOption: WithMenuOption = mdDown
        ? isSubscriptionCancelling
            ? {
                  label: subscriptionOptionText,
                  onClick: async () => {
                      await toast.promise(resubscribe(), {
                          loading: "Restarting your subscription...",
                          success: "Success!",
                          error: "Please try again later.",
                      });
                  },
              }
            : {
                  label: subscriptionOptionText,
                  href: "/plans",
              }
        : null;

    const options: WithMenuOption[] = [
        {
            label: "Profile",
            node: (
                <FlexRowAlignCenter style={{ width: "100%" }}>
                    <FlexRowAlignCenter style={{ gap: "1.5rem", width: "100%" }}>
                        <UserAvatar
                            radius="3rem"
                            src={user?.pictureUrl || pictureUrl}
                            alt={user?.Name || user?.username}
                        />

                        <FlexColumn>
                            <span className={"ellipsisText"} style={{ fontSize: "1.5rem", fontWeight: "600" }}>
                                {user?.Name || "Unnamed"}
                            </span>
                            <span className={"ellipsisText"} style={{ fontSize: "1.2rem", fontWeight: "400" }}>
                                {user?.Email}
                            </span>
                        </FlexColumn>
                    </FlexRowAlignCenter>
                </FlexRowAlignCenter>
            ),
            href: `/u/${user?.username}`,
            menuItemProps: {
                sx: {
                    borderBottom: `0.1rem solid ${themeColors.neutral1}`,
                },
            },
        },
        {
            label: "Profile",
            node: (
                <FlexRowAlignCenter>
                    <User size={iconSizes.SM} style={{ marginRight: "1rem" }} />
                    Profile
                </FlexRowAlignCenter>
            ),
            href: `/u/${user?.username}`,
        },

        {
            label: `Dark/Light mode enabler`,
            node: (
                <FlexRowAlignCenter>
                    <Moon size={iconSizes.SM} style={{ marginRight: "1rem" }} />
                    Enable {isDarkMode ? "Light" : "Dark"} Mode
                </FlexRowAlignCenter>
            ),
            onClick: () => changeTheme(isDarkMode ? "light" : "dark"),
        },
        {
            label: `Check AI usage`,
            node: (
                <FlexRowAlignCenter>
                    <Sparkles size={iconSizes.SM} style={{ marginRight: "1rem" }} />
                    Check AI Usage
                </FlexRowAlignCenter>
            ),
            href: "/settings/?tab=aiUsage",
        },
        {
            label: "Privacy",
            node: (
                <FlexRowAlignCenter>
                    <GlobeLock size={iconSizes.SM} style={{ marginRight: "1rem" }} />
                    Privacy
                </FlexRowAlignCenter>
            ),
            onClick: () => window.open("/privacy", "_blank"),
        },
        {
            label: "Terms",
            node: (
                <FlexRowAlignCenter>
                    <FileText size={iconSizes.SM} style={{ marginRight: "1rem" }} />
                    Terms
                </FlexRowAlignCenter>
            ),
            onClick: () => window.open("/terms", "_blank"),
        },
        ...(canShowAds({ user, organization }) ? [subscriptionOption] : []),
        {
            label: "Log Out",
            node: (
                <FlexRowAlignCenter>
                    <LogOut size={iconSizes.SM} style={{ marginRight: "1rem" }} />
                    Log Out
                </FlexRowAlignCenter>
            ),
            onClick: async () => {
                await signOut();
                window.location.href = "/goodbye";
            },
            menuItemProps: {
                sx: {
                    color: themeColors.errorPrimary,
                    borderTop: `0.1rem solid ${themeColors.neutral1}`,
                    paddingBottom: "1.2rem",
                },
            },
        },
    ].filter(option => !!option) as WithMenuOption[];

    return (
        <WithOptionsMenu
            menuItemProps={{ sx: { padding: "1rem 1.6rem" } }}
            menuProps={{
                slotProps: {
                    root: {
                        style: {
                            zIndex: 99999,
                        },
                    },
                },
                PaperProps: {
                    sx: {
                        borderRadius: "1rem",
                        backgroundColor: themeColors.background,
                        width: "26.7rem",
                    },
                },
                anchorOrigin: { vertical: footerAds ? -100 : 0, horizontal: isSidebarExpanded ? 220 : 60 },
                transformOrigin: { vertical: "center", horizontal: "left" },
            }}
            options={options}>
            {({ openMenu }) => (
                <UserAvatar
                    component={"button"}
                    className="strippedBtn"
                    onClick={e => {
                        e.stopPropagation();
                        openMenu(e);
                    }}
                    style={{ cursor: "pointer" }}
                    radius={radius || "4rem"}
                    src={user?.pictureUrl || pictureUrl || ""}
                    alt={user?.Name || user?.username || "U"}
                />
            )}
        </WithOptionsMenu>
    );
};

export default ProfileCircleWithMenu;
