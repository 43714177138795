"use client";

import SWRConfig from "@/components/ClientSWRConfig";
import { InnerContainer, MainContainer } from "@/components/Containers";
import PlanPromotion from "@/features/InitialSetupPage/PlanPromotion";
import LocalKeyValueStore from "@/utils/LocalKeyValueStore";
import { SubscriptionType } from "@knowt/syncing/graphql/schema";
import GUIDED_EVENTS, { AllEventNames } from "@knowt/syncing/hooks/guidedEvents/guidedEvents";
import { INITIAL_SETUP_STEPS, getInitialSetupSteps } from "@knowt/syncing/hooks/guidedEvents/initialSetupConstants";
import useGuidedEvent from "@knowt/syncing/hooks/guidedEvents/useGuidedEvent";
import { StableSWRKeys } from "@knowt/syncing/hooks/swr/swr";
import { LocalAuthUser, LocalUser } from "@knowt/syncing/hooks/user/types";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useRouter } from "@bprogress/next";
import { type JSX, useEffect, useState } from "react";
import AccountSchoolSelection from "./components/userSetupPage/AccountSchoolSelection";
import LinkSSOAccountStep from "./components/userSetupPage/LinkSSOAccountStep";
import LinkSchoolEmailStep from "./components/userSetupPage/LinkSchoolEmailStep";
import OrganizationManagedAccountStep from "./components/userSetupPage/OrganizationManagedAccountStep";
import OrganizationUpgradedAccountStep from "./components/userSetupPage/OrganizationUpgradedAccountStep";
import { getInitialSetupUrl } from "./utils";
import HowDidYouHearAndWannaUse from "./components/userSetupPage/HowDidYouHearAndWannaUse";
import BasicIdentity from "./components/userSetupPage/BasicIdentity";
import { redirectAfterLoginIfNeeded } from "@/app/page/rootComponents/AuthHubListener";
import UnusualConversion from "@/components/unusual/UnusualConversion";
import { UNUSUAL_CONVERSION_ACTION } from "@/components/unusual/constants";

export const useCheckToDisplayInitialAccountSetupPage = ({ fallbackData }: { fallbackData?: LocalUser }) => {
    const router = useRouter();

    const { user, loginInProgress, organization } = useCurrentUser({ fallbackData });

    // biome-ignore lint: useExhaustiveDependencies
    useEffect(() => {
        const asyncEffect = async () => {
            if (loginInProgress || !user) return;

            const migKey = (await LocalKeyValueStore.getWithExpiry("migKey")) as string;
            const oldAccountUserId = (await LocalKeyValueStore.getWithExpiry("userId")) as string;

            if (migKey && oldAccountUserId) {
                // let migration happen
                return;
            }

            const pathname = window.location.pathname;

            if (pathname === "/sso/login") {
                // let sso login happen
                return;
            }

            const initialSetupUrl = await getInitialSetupUrl({ user, organization });

            if (initialSetupUrl) {
                router.replace(initialSetupUrl);
            }
        };

        asyncEffect();

        // we only want this to check once on page load
    }, [user, loginInProgress]);

    return undefined;
};

const InitialSetupPage = ({ serverUser }: { serverUser: LocalAuthUser }) => {
    const { user, organization, subscriptionType } = useCurrentUser({ fallbackData: serverUser });

    const [currentStep, setCurrentStep] = useState(0);

    const { closeEvent, stagedEvents } = useGuidedEvent(GUIDED_EVENTS.PLAN_PROMOTION);

    const endTheFlow = async () => {
        redirectAfterLoginIfNeeded();
    };

    const [initialSetupSteps, setInitialSetupSteps] = useState(() => getInitialSetupSteps({ user, organization }));

    const showNextStep = async () => {
        if (currentStep === initialSetupSteps.length - 1) {
            endTheFlow();
            return;
        }

        setCurrentStep(currentStep + 1);
    };

    useEffect(() => {
        if (stagedEvents?.length && subscriptionType !== SubscriptionType.BASIC) {
            closeEvent(AllEventNames.STUDENT_TRIAL_2024);
        }
    }, [closeEvent, stagedEvents, subscriptionType]);

    const StepComponents: Record<INITIAL_SETUP_STEPS, () => JSX.Element> = {
        [INITIAL_SETUP_STEPS.BASIC_INFO]: () => (
            <BasicIdentity
                onNext={({ user: updatedUser }) => {
                    // once user account type/birthday is set, recalculate the steps
                    const steps = getInitialSetupSteps({ user: updatedUser, organization });

                    setInitialSetupSteps(steps);
                    setCurrentStep(0);
                }}
            />
        ),
        [INITIAL_SETUP_STEPS.REFERRAL]: () => <HowDidYouHearAndWannaUse onNext={showNextStep} />,
        [INITIAL_SETUP_STEPS.SCHOOL_SELECTION]: () => (
            <AccountSchoolSelection onNext={() => window.location.reload()} organization={organization} />
        ),
        [INITIAL_SETUP_STEPS.LINK_SSO_ACCOUNT]: () => <LinkSSOAccountStep />,
        [INITIAL_SETUP_STEPS.LINK_EMAIL_ACCOUNT]: () => <LinkSchoolEmailStep organization={organization} />,
        [INITIAL_SETUP_STEPS.ORG_MANAGED_ACCOUNT]: () => <OrganizationManagedAccountStep />,
        [INITIAL_SETUP_STEPS.ORG_UPGRADED_ACCOUNT]: () => <OrganizationUpgradedAccountStep />,
        [INITIAL_SETUP_STEPS.PLAN_PROMOTION]: () => <PlanPromotion />,
    };

    if (initialSetupSteps.length === 0) {
        endTheFlow();
    }

    return (
        <SWRConfig
            value={{
                fallback: {
                    [StableSWRKeys.USER]: serverUser,
                },
            }}>
            <UnusualConversion id={UNUSUAL_CONVERSION_ACTION.SIGN_UP} />
            <MainContainer
                noNavBar
                noSidebar
                style={{
                    paddingBlock: "4.5rem 4.8rem",
                    marginInline: "4rem 4rem",
                    marginBlock: "0rem 0rem",
                    minHeight: "calc(99dvh - var(--navbar-height))",
                }}>
                <InnerContainer>{StepComponents[initialSetupSteps[currentStep]?.key]?.()}</InnerContainer>
            </MainContainer>
        </SWRConfig>
    );
};

export default InitialSetupPage;
