import { CompositeFlow } from "pointer-sdk";
import { CREATE_NEW_CONTENT_BTN_ID } from "../CreateNewContent/CreateNewContentButtonWithHandler";
import { AICompletionType } from "@knowt/syncing/graphql/schema";

const TEACHER_ONBOARDING: CompositeFlow = {
    id: "teacher-onboarding-flow",
    type: "composite",
    name: "Teacher onboarding flow",
    description: "Welcome to the new Knowt experience",
    maxShows: 1,
    welcome: {
        title: "Hi there! 👋",
        subtitle: "Welcome to the new Knowt.",
        description: "If you’re new here, or are coming back on for the first time since our update - let’s do a tour!",
        image: {
            light: "/images/pointer-welcome-light.png",
            dark: "/images/pointer-welcome-dark.png",
        },
        buttonText: "Take a tour",
    },
    subflows: [
        {
            id: "general-tour-teacher",
            type: "simple",
            name: "General overview",
            allowRetrigger: true,
            description: "Get familiar with the new Knowt interface",
            steps: [
                {
                    id: "show-sidenav",
                    type: "self-paced",
                    position: "sidebar-tab-home",
                    content: {
                        description: "Access all your tools and materials from this new side navigation",
                    },
                },
                {
                    id: "show-materials",
                    type: "guided",
                    position: "sidebar-tab-files",
                    content: {
                        description: "Find all your teaching materials organized in one place",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "show-classes",
                    type: "guided",
                    position: "sidebar-tab-classes",
                    content: {
                        description: "Manage all your classes in one place",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "show-ai-tools",
                    type: "guided",
                    position: "sidebar-tab-ai_tools",
                    content: {
                        description: "Access powerful AI tools to enhance your teaching",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "show-create",
                    type: "self-paced",
                    position: CREATE_NEW_CONTENT_BTN_ID,
                    content: {
                        description: "Create new notes, flashcards, and more from here",
                    },
                },
                // {
                //     id: "choose-notes",
                //     type: "guided",
                //     position: "create-popup-tab-notes",
                //     content: {
                //         description: "Choose between what to create by clicking through the options here",
                //     },
                //     action: {
                //         type: "click",
                //     },
                // },
            ],
        },
        {
            id: "ai-tools-tour",
            type: "simple",
            name: "Go to AI tools page",
            allowRetrigger: true,
            description: "Discover our powerful AI tools for teachers",
            steps: [
                {
                    id: "open-ai-tools",
                    type: "guided",
                    position: "sidebar-tab-ai_tools",
                    content: {
                        description: "Click here to explore our AI tools",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "show-categories",
                    type: "guided",
                    position: "ai-tools-filter-bar-Planning",
                    page: "/free-ai-tools-for-teachers",
                    content: {
                        description: "Choose between different categories of AI tools",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "open-lesson-plan",
                    type: "guided",
                    position: AICompletionType.LESSON_PLAN,
                    page: "/free-ai-tools-for-teachers",
                    content: {
                        description: "Let’s plan a lesson for your class now!",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "open-lesson-plan",
                    type: "self-paced",
                    position: "LESSON_PLAN-grade",
                    page: "/free-ai-tools-for-teachers/ai-lesson-plan-generator",
                    content: {
                        description: "Put in the grade level you teach",
                    },
                },
                {
                    id: "open-lesson-plan",
                    type: "self-paced",
                    position: "LESSON_PLAN-topic",
                    page: "/free-ai-tools-for-teachers/ai-lesson-plan-generator",
                    content: {
                        description: "Put in the topic you want to create a lesson plan for",
                    },
                },
                {
                    id: "open-lesson-plan",
                    type: "self-paced",
                    position: "LESSON_PLAN-time",
                    page: "/free-ai-tools-for-teachers/ai-lesson-plan-generator",
                    content: {
                        description: "Put in the duration of the lesson",
                    },
                },
                {
                    id: "open-lesson-plan",
                    type: "guided",
                    position: "LESSON_PLAN-generate",
                    page: "/free-ai-tools-for-teachers/ai-lesson-plan-generator",
                    content: {
                        description: "Generate your lesson plan!",
                    },
                    action: {
                        type: "click",
                    },
                },
            ],
        },
        {
            id: "create-class-tour",
            type: "simple",
            name: "Create a class",
            allowRetrigger: true,
            description: "Learn how to set up and manage your classes",
            steps: [
                {
                    id: "open-classes",
                    type: "guided",
                    position: "sidebar-tab-classes",
                    content: {
                        description: "Click on the classes tab",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "create-class-button",
                    type: "guided",
                    position: "create-new-content-btn-class",
                    page: "/classes",
                    content: {
                        description: "Click create or join to get started",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "create-class-button",
                    type: "guided",
                    position: "create-class-button",
                    page: "/classes",
                    content: {
                        description: "Click on the create button to create a class",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "enter-class-info",
                    type: "self-paced",
                    position: "class-info-form",
                    content: {
                        description: "Enter your class information here",
                    },
                },
                {
                    id: "add-sections",
                    type: "self-paced",
                    position: "create-class-add-sections",
                    content: {
                        description: "Add sections to organize your class",
                    },
                },
                {
                    id: "add-sections",
                    type: "guided",
                    position: "create-class-popup-create-button",
                    content: {
                        description: "When you're done, click on the create button to create your class!",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "show-materials-tab",
                    type: "guided",
                    position: "class-tab-switcher-materials",
                    page: "/class/:slug/dashboard",
                    content: {
                        description: "This is where all your class materials will live",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "show-people-tab",
                    type: "guided",
                    position: "class-tab-switcher-people",
                    page: "/class/:slug/dashboard",
                    content: {
                        description: "This is where you can manage your class members and invite new ones",
                    },
                    action: {
                        type: "click",
                    },
                },
            ],
        },
        {
            id: "referral-program",
            type: "simple",
            name: "Refer fellow teachers",
            allowRetrigger: true,
            description: "Get rewards by referring other teachers to Knowt",
            steps: [
                {
                    id: "show-referral",
                    type: "guided",
                    position: "sidebar-tab-referral",
                    content: {
                        description: "Love Knowt? Refer other teachers to get exclusive rewards!",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "referral-code",
                    type: "self-paced",
                    position: "profile-screen-referral-section",
                    page: "/u/:username",
                    content: {
                        description: "This is your unique referral code and link",
                    },
                },
                {
                    id: "referral-rewards",
                    type: "self-paced",
                    position: "profile-screen-referral-rewards",
                    page: "/u/:username",
                    content: {
                        description:
                            "When other teachers sign up using your link or code, you will get coins which are redeemable for merch, features, & more in the Knowt store!",
                    },
                },
                {
                    id: "view-store",
                    type: "guided",
                    position: "profile-screen-store-button",
                    page: "/u/:username",
                    content: {
                        description: "Check out what you can redeem by visiting the store here!",
                    },
                    action: {
                        type: "click",
                    },
                },
            ],
        },
    ],
    checklistConfig: {
        title: "Get started with Knowt",
        description: "Complete these items to master the new Knowt experience",
        position: "bottom-end",
        offset: 10,
        triggerId: "show-pointer-checklist",
        minimizable: true,
    },
};

export default TEACHER_ONBOARDING;
