import { CompositeFlow } from "pointer-sdk";
import { CREATE_NEW_CONTENT_BTN_ID } from "../CreateNewContent/CreateNewContentButtonWithHandler";

const STUDENT_ONBOARDING: CompositeFlow = {
    id: "student-onboarding-flow",
    type: "composite",
    name: "Student onboarding flow",
    description: "Welcome to the new Knowt.",
    maxShows: 1,
    welcome: {
        title: "Hi there! 👋",
        subtitle: "Welcome to the new Knowt.",
        description: "If you’re new here, or are coming back on for the first time since our update - let’s do a tour!",
        image: {
            light: "/images/pointer-welcome-light.png",
            dark: "/images/pointer-welcome-dark.png",
        },
        buttonText: "Take a tour",
    },
    subflows: [
        {
            id: "general-tour-student",
            type: "simple",
            name: "General overview",
            allowRetrigger: true,
            description: "Get familiar with the new Knowt interface",
            steps: [
                {
                    id: "show-sidenav",
                    type: "self-paced",
                    position: "sidebar-tab-home",
                    content: {
                        description: "Access all your tools and materials from this new side navigation",
                    },
                },
                {
                    id: "show-materials",
                    type: "guided",
                    position: "sidebar-tab-files",
                    content: {
                        description: "Click here to find all your study materials organized in one place",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "show-ai-tools",
                    type: "self-paced",
                    position: "sidebar-tab-ai_pdf_summarizer",
                    content: {
                        description: "Use our AI tools to upload a file and instantly get notes & flashcards",
                    },
                },
                {
                    id: "show-create",
                    type: "self-paced",
                    position: CREATE_NEW_CONTENT_BTN_ID,
                    content: {
                        description: "Create new notes, flashcards, and more from here",
                    },
                },
                // {
                //     id: "choose-notes",
                //     type: "guided",
                //     position: "create-popup-tab-notes",
                //     content: {
                //         description: "Choose between what to create by clicking through the options here",
                //     },
                //     action: {
                //         type: "click",
                //     },
                // },
            ],
        },
        {
            id: "pdf-summarizer-tour",
            type: "simple",
            name: "Try our AI PDF summarizer",
            allowRetrigger: true,
            description:
                "Upload any PDF, PPT, Video, or audio recording and Kai will create notes and flashcards instantly from them",
            steps: [
                {
                    id: "open-pdf-summarizer",
                    type: "guided",
                    position: "sidebar-tab-ai_pdf_summarizer",
                    content: {
                        description: "Click here to open the PDF summarizer",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "upload-file",
                    type: "guided",
                    position: "dropzone-upload-button",
                    page: "/ai-pdf-summarizer",
                    content: {
                        description: "Upload a PDF file to get started",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "upload-file",
                    type: "guided",
                    position: "dropzone-confirm-button",
                    content: {
                        description: "Confirm your file and click upload.",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "create-flashcards",
                    type: "self-paced",
                    position: "media-ai-flashcardset-button",
                    content: {
                        description: "Convert your PDF into flashcards",
                    },
                },
            ],
        },
        {
            id: "create-note-tour",
            type: "simple",
            name: "Create a note",
            allowRetrigger: true,
            description: "Learn how to create notes with AI assistance",
            steps: [
                {
                    id: "click-create",
                    type: "guided",
                    position: CREATE_NEW_CONTENT_BTN_ID,
                    content: {
                        description: "Click the create button to start",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "choose-notes",
                    type: "guided",
                    position: "create-popup-tab-notes",
                    content: {
                        description: "Select notes to create a new note",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "create-from-scratch",
                    type: "guided",
                    position: "create-popup-tab-notes-create-from-scratch",
                    content: {
                        description: "Start with a blank note",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "try-chat-kai",
                    type: "guided",
                    position: "notes-page-chat-kai-button",
                    content: {
                        description: "Try using our AI assistant Kai to help with your notes",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "create-study-guide",
                    type: "guided",
                    position: "note-chat-make-study-guide",
                    content: {
                        description: "Try asking Kai to make you a study guide.",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "create-study-guide",
                    type: "self-paced",
                    position: "kai-chat-input",
                    content: {
                        description:
                            "Type in a topic to generate notes on. Ask for edits until its perfect, then add it to your note!",
                    },
                },
            ],
        },
        {
            id: "referral-program",
            type: "simple",
            name: "Refer friends",
            allowRetrigger: true,
            description: "Get rewards by referring your friends to Knowt",
            steps: [
                {
                    id: "show-referral",
                    type: "guided",
                    position: "sidebar-tab-referral",
                    content: {
                        description: "Love Knowt? Refer others to get exclusive rewards!",
                    },
                    action: {
                        type: "click",
                    },
                },
                {
                    id: "referral-code",
                    type: "self-paced",
                    position: "profile-screen-referral-section",
                    page: "/u/:username",
                    content: {
                        description: "This is your unique referral code and link",
                    },
                },
                {
                    id: "referral-rewards",
                    type: "self-paced",
                    position: "profile-screen-referral-rewards",
                    page: "/u/:username",
                    content: {
                        description:
                            "When people sign up using your link or code, you will get coins which are redeemable for merch, features, & more in the Knowt store!",
                    },
                },
                {
                    id: "view-store",
                    type: "guided",
                    position: "profile-screen-store-button",
                    page: "/u/:username",
                    content: {
                        description: "Check out what you can redeem by visiting the store here!",
                    },
                    action: {
                        type: "click",
                    },
                },
            ],
        },
    ],
    checklistConfig: {
        title: "Getting started 👋🏾",
        description: "Complete these items to master the new Knowt experience",
        position: "bottom-end",
        offset: 10,
        triggerId: "show-pointer-checklist",
        minimizable: true,
    },
};

export default STUDENT_ONBOARDING;
