import WithOptionsMenu, { WithMenuOption } from "@/components/WithOptionsMenu";

import { themeColors } from "@/utils/themeColors";
import { BadgeHelp, MessageCircleIcon, NewspaperIcon } from "lucide-react";
import { FlexRowAlignCenter } from "@/components/Flex";
import { iconSizes } from "@/utils/iconProps";
import Link from "next/link";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { spacing } from "@/utils/spacing";
import { showIntercom } from "@/utils/intercom";
import { SidebarItemBtn } from "./styled/SidebarItemBtn";
import { SidebarTab } from "../utils";

const HelpIconWithMenu = ({
    footerAds = false,
    isExpanded,
}: {
    footerAds?: boolean;
    isExpanded: boolean;
}) => {
    // const { showChecklist } = useFlow();

    const menuOptions: WithMenuOption[] = [
        {
            label: "Help Articles",
            node: (
                <Link
                    href="https://help.knowt.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="strippedLink"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                    }}>
                    <NewspaperIcon size={iconSizes.MD_S} />
                    <span
                        className="secondaryText1"
                        style={{
                            marginLeft: "1rem",
                        }}>
                        Help Articles
                    </span>
                    <CircularRectTextButton
                        className="secondaryTextBold1"
                        style={{
                            padding: `${spacing.XXS} ${spacing.XS}`,
                            backgroundColor: themeColors.helpInstant,
                            color: themeColors.pureBlack,
                            marginLeft: "auto",
                        }}>
                        Instant
                    </CircularRectTextButton>
                </Link>
            ),
            onClick: () => {
                typeof window !== "undefined" && window?.open("https://help.knowt.com", "_blank");
            },
        },
        // {
        //     label: "Guide me on how to do something!",
        //     node: (
        //         <FlexRowAlignCenter
        //             style={{
        //                 width: "100%",
        //             }}>
        //             <HandHelpingIcon size={iconSizes.MD_S} />
        //             <span
        //                 className="secondaryText1"
        //                 style={{
        //                     marginLeft: "1rem",
        //                 }}>
        //                 Guide me on how to do something!
        //             </span>
        //             <CircularRectTextButton
        //                 className="secondaryTextBold1"
        //                 style={{
        //                     padding: `${spacing.XXS} ${spacing.XS}`,
        //                     backgroundColor: themeColors.helpInstant,
        //                     color:themeColors.pureBlack
        //                     marginLeft: "auto",
        //                 }}>
        //                 Instant
        //             </CircularRectTextButton>
        //         </FlexRowAlignCenter>
        //     ),
        //     onClick: showChecklist,
        // },
        {
            label: "Contact Support ",
            node: (
                <FlexRowAlignCenter
                    style={{
                        width: "100%",
                    }}>
                    <MessageCircleIcon size={iconSizes.MD_S} />

                    <span
                        className="secondaryText1"
                        style={{
                            marginLeft: "1rem",
                        }}>
                        Contact Support
                    </span>
                    <CircularRectTextButton
                        className="secondaryTextBold1"
                        style={{
                            padding: `${spacing.XXS} ${spacing.XS}`,
                            backgroundColor: themeColors.errorSecondary,
                            marginLeft: "auto",
                        }}>
                        10-15 min
                    </CircularRectTextButton>
                </FlexRowAlignCenter>
            ),
            onClick: showIntercom,
        },
    ];

    return (
        <WithOptionsMenu
            menuItemProps={{ sx: { padding: "1rem 1.6rem" } }}
            menuProps={{
                slotProps: {
                    root: {
                        style: {
                            zIndex: 99999,
                        },
                    },
                },
                PaperProps: {
                    sx: {
                        borderRadius: "2rem",
                        backgroundColor: themeColors.background,
                        width: "40rem",
                    },
                },
                anchorOrigin: { vertical: footerAds ? -100 : 0, horizontal: isExpanded ? 220 : 60 },
                transformOrigin: { vertical: "center", horizontal: "left" },
            }}
            options={menuOptions}>
            {({ openMenu }) => (
                <SidebarItemBtn
                    id={`sidebar-tab-${SidebarTab.HELP}`}
                    isExpanded={!!isExpanded}
                    label={"Help"}
                    Icon={BadgeHelp}
                    tooltip={!isExpanded ? "Classes" : undefined}
                    onClick={openMenu}
                />
            )}
        </WithOptionsMenu>
    );
};

export default HelpIconWithMenu;
