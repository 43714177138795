import { themeColors } from "@/utils/themeColors";
import Box, { BoxProps } from "@mui/material/Box";
import React from "react";

export type RoundInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    sx?: BoxProps["sx"];
    component?: BoxProps["component"];
    ref?: React.ForwardedRef<HTMLInputElement>;
};

const RoundInput = ({ ref, sx, component, ...inputProps }: RoundInputProps) => {
    return (
        <Box
            component={component || "input"}
            ref={ref}
            sx={{
                fontFamily: "var(--knowt-font-name)",
                fontSize: "1.6rem",
                color: themeColors.neutralBlack,
                backgroundColor: themeColors.background,
                outline: "none",
                borderRadius: 999,
                paddingLeft: "1.6rem",
                paddingRight: "1rem",
                border: "unset",
                width: "100%",
                ...sx,
            }}
            {...inputProps}
        />
    );
};

export default RoundInput;
