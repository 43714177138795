import { AutocompleteGroupedOption, UseAutocompleteProps, useAutocomplete } from "@mui/base/useAutocomplete";
import React, { Fragment } from "react";

import { Flex } from "@/components/Flex";
import { iconSizes } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";
import { ChevronDown } from "lucide-react";
import Mountable from "../styled/div/Mountable";

export type OptionProps<T> = {
    label: string;
    value: T;
    node?: React.ReactNode;
    group?: string;
};

export type InputWithMenuProps<T> = {
    rootProps?: React.InputHTMLAttributes<HTMLDivElement>;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement> & { sx?: SxProps };
    ulProps?: React.HTMLAttributes<HTMLUListElement> & { sx?: SxProps };
    liProps?: React.HTMLAttributes<HTMLLIElement> & { sx?: SxProps };
    liHeaderProps?: React.HTMLAttributes<HTMLLIElement> & { sx?: SxProps };
    noOptionsText?: React.ReactNode;
    placeholder?: React.ReactNode;
    useAutocompleteProps: UseAutocompleteProps<OptionProps<T>, false, false, false>;
    adornment?: React.ReactNode;
};

/**
 * Barebone input-with-menu using MUI's useAutocomplete hook
 * - disable autoselect by setting `autoHighlight` to false
 * - pass `null` to `noOptionsText` to disable it
 * - we force the `useAutocomplete.options` structure to be always `{ label: string; value: unknown }`
 * - `ulProps` is props of the listbox (suggestion.ts menu body)
 * - `liProps` is props of the listbox option (item within the suggestion.ts menu body)
 * - `liHeaderProps` is props of the listbox option header (group name of the items if `option.group` is defined)
 */
const InputWithMenu = <T,>({
    rootProps,
    inputProps,
    ulProps,
    liProps,
    liHeaderProps,
    noOptionsText = "No available options",
    placeholder,
    useAutocompleteProps,
    adornment = <ChevronDown size={iconSizes.SM} fill={"currentColor"} strokeWidth={0} />,
}: InputWithMenuProps<T>) => {
    const { inputValue, getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions, expanded } =
        useAutocomplete({
            id: "use-autocomplete-input-with-menu",
            getOptionLabel: option => option.label,
            autoHighlight: true,
            isOptionEqualToValue: (option, value) => value && option.value === value.value,
            ...useAutocompleteProps,
        });

    const isOptionsEmpty = (() => {
        if (useAutocompleteProps.options[0]?.group) {
            return (groupedOptions as AutocompleteGroupedOption<OptionProps<T>>[]).length === 0;
        }
        return (groupedOptions as OptionProps<T>[]).filter(option => option.label).length === 0;
    })();

    const renderOptions = () => {
        if (useAutocompleteProps.options[0]?.group) {
            return (
                // options with group (has header list)
                <Ul {...getListboxProps()} {...ulProps}>
                    {(groupedOptions as AutocompleteGroupedOption<OptionProps<T>>[]).map(
                        ({ key: groupKey, index: groupIdx, group, options }, i) => {
                            return (
                                <Fragment key={groupKey + groupIdx + i}>
                                    <LiHeader {...liHeaderProps}>{group}</LiHeader>
                                    {options.map((option, optionIdx) => {
                                        const optionKey = group + optionIdx.toString() + option.value + option.label;
                                        return (
                                            <Li
                                                {...getOptionProps({ option, index: groupIdx + optionIdx })}
                                                key={optionKey}>
                                                {option.node || option.label}
                                            </Li>
                                        );
                                    })}
                                </Fragment>
                            );
                        }
                    )}
                </Ul>
            );
        }

        return (
            <Ul {...getListboxProps()} {...ulProps}>
                {(groupedOptions as OptionProps<T>[]).map((option, index) => (
                    <Li
                        {...getOptionProps({ option, index })}
                        {...liProps}
                        key={index.toString() + option.value + option.label}>
                        {option.node || option.label}
                    </Li>
                ))}
            </Ul>
        );
    };

    return (
        <div {...getRootProps()} {...rootProps} style={{ position: "relative", ...rootProps?.style }}>
            <Input
                {...getInputProps()}
                {...inputProps}
                value={inputValue || useAutocompleteProps?.value?.value}
                sx={{
                    ...inputProps?.sx,
                    paddingRight: "4rem",
                }}
            />
            <Mountable mount={!!adornment}>
                <Flex style={{ position: "absolute", right: "1.5rem", top: "50%", transform: "translateY(-50%)" }}>
                    {adornment}
                </Flex>
            </Mountable>
            {!isOptionsEmpty ? renderOptions() : null}

            {/**
             * NOTE:
             * somehow `expanded` is `null` when the popup is opened and `false` when the popup closed by "enter"
             */}
            {isOptionsEmpty && inputValue && noOptionsText && expanded == null ? (
                // no options text
                <Ul {...getListboxProps()} {...ulProps}>
                    <Li {...liProps} style={{ whiteSpace: "initial" }}>
                        {noOptionsText}
                    </Li>
                </Ul>
            ) : null}
            {isOptionsEmpty && !inputValue && placeholder && expanded == null ? (
                // no options text
                <Ul {...getListboxProps()} {...ulProps}>
                    <Li {...liProps} style={{ whiteSpace: "initial" }}>
                        {placeholder}
                    </Li>
                </Ul>
            ) : null}
        </div>
    );
};

export default InputWithMenu;

export const Input = ({ ref, sx, ...props }) => {
    return (
        <Box
            ref={ref}
            component={"input"}
            {...props}
            sx={{
                width: "100%",
                fontFamily: "var(--knowt-font)",
                backgroundColor: themeColors.background,
                fontSize: "inherit",
                color: themeColors.neutralBlack,
                outline: "none",
                border: "none",
                padding: 0,
                ...sx,
            }}
        />
    );
};

const Ul = ({ ref, sx, ...props }) => {
    return (
        <Box
            ref={ref}
            component={"ul"}
            {...props}
            sx={{
                width: "100%",
                zIndex: 1,
                position: "absolute",
                backgroundColor: themeColors.background,
                overflow: "auto",
                fontSize: "inherit",
                maxHeight: "18rem",
                "& li.Mui-focused": {
                    color: themeColors.primary,
                    cursor: "pointer",
                },
                "& li:active": {
                    color: themeColors.primary,
                },
                listStyle: "none",
                padding: "0.64rem 0",
                margin: 0,
                ...sx,
            }}
        />
    );
};

const Li = ({ ref, children, sx, ...props }) => {
    return (
        <Box
            ref={ref}
            component={"li"}
            {...props}
            sx={{
                padding: "0.32rem 1.28rem",
                margin: "0.2rem",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                ...sx,
            }}>
            {children}
        </Box>
    );
};

const LiHeader = ({ ref, children, sx, ...props }) => {
    return (
        <Box
            ref={ref}
            component={"li"}
            {...props}
            sx={{
                padding: "0.3rem 1rem",
                position: "sticky",
                top: "-0.64rem",
                backgroundColor: themeColors.neutral3,
                color: themeColors.neutralWhite,
                fontWeight: "700",
                opacity: 1,
                ...sx,
            }}>
            {children}
        </Box>
    );
};
