import { useThemeContextSelector } from "@/utils/theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";

/**
 * Mathc MUI css config following our design system
 */
const KnowtMUIThemeProvider = ({ children }: { children: React.ReactNode }) => {
    const isDarkMode = useThemeContextSelector(state => state.isDarkMode);
    const mode = isDarkMode ? "dark" : "light";

    return (
        <ThemeProvider
            theme={createTheme({
                typography: {
                    htmlFontSize: 10,
                    fontFamily: [
                        "var(--knowt-font-name)",
                        "-apple-system",
                        "BlinkMacSystemFont",
                        '"Segoe UI"',
                        "Roboto",
                        '"Helvetica Neue"',
                        "Arial",
                        "sans-serif",
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                    ].join(","),
                },
                palette: {
                    mode,
                    ...(mode === "light"
                        ? { text: { primary: "#092522", secondary: "#4a4a4a" } }
                        : { text: { primary: "#e7e7e7", secondary: "#e9e9e9" } }),
                },
            })}>
            {children}
        </ThemeProvider>
    );
};

export default KnowtMUIThemeProvider;
