import React from "react";
import InputWithMenu, { InputWithMenuProps } from "@/components/InputWithMenu";
import { themeColors } from "@/utils/themeColors";
import { useTaggingStyleModifierContextSelector } from "../../hooks/useTaggingStyleModifierContext";

// Default UI for tagging autocomplete
const TaggingAutocomplete = ({
    ulProps,
    liProps,
    liHeaderProps,
    useAutocompleteProps,
    placeholder,
    inputProps,
    ...inputWithMenuProps
}: InputWithMenuProps) => {
    const inputStyle = useTaggingStyleModifierContextSelector(state => state.inputStyle);
    const ulStyle = useTaggingStyleModifierContextSelector(state => state.ulStyle);
    const liStyle = useTaggingStyleModifierContextSelector(state => state.liStyle);
    const liHeaderStyle = useTaggingStyleModifierContextSelector(state => state.liHeaderStyle);
    const contextInputProps = useTaggingStyleModifierContextSelector(state => state.inputProps);

    return (
        <InputWithMenu
            useAutocompleteProps={useAutocompleteProps}
            {...inputWithMenuProps}
            inputProps={{
                ...inputProps,
                ...contextInputProps,
                sx: {
                    border: `1px solid ${themeColors.neutral1}`,
                    borderRadius: "999px",
                    height: "4.8rem",
                    backgroundColor: themeColors.neutralWhite,
                    padding: "0 1.5rem",
                    ...inputStyle,
                    ...inputProps?.sx,
                },
            }}
            ulProps={{
                ...ulProps,
                sx: {
                    backgroundColor: themeColors.neutralWhite,
                    borderRadius: "1.2rem",
                    top: "5.6rem",
                    maxHeight: "18.5rem",
                    boxShadow: "0 6px 20px 0 rgba(0,0,0,.08)",
                    ...ulStyle,
                    ...ulProps?.sx,
                },
            }}
            placeholder={placeholder}
            liProps={{ ...liProps, sx: { ...liStyle, ...liProps?.sx } }}
            liHeaderProps={{ ...liHeaderProps, sx: { ...liHeaderStyle, ...liHeaderProps?.sx } }}
        />
    );
};

export default TaggingAutocomplete;

type TaggingLabelProps = { children: React.ReactNode; htmlFor?: string } & React.HTMLAttributes<HTMLLabelElement>;
export const TaggingLabel = ({ children, style, htmlFor, ...labelProps }: TaggingLabelProps) => {
    const labelStyle = useTaggingStyleModifierContextSelector(state => state.labelStyle);
    return (
        <label className="secondaryTextBold1" {...labelProps} htmlFor={htmlFor} style={{ ...style, ...labelStyle }}>
            {children}
        </label>
    );
};
