import { useUserAlertsSelector } from "@/hooks/guidedEvents/UserAlerts";
import { shouldSkipAlert } from "@/hooks/guidedEvents/utils";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { platform } from "@/platform";
import {
    GuidedEvent,
    GuidedEventAction,
    GuidedEventObject,
    allEventNamesList,
} from "@knowt/syncing/hooks/guidedEvents/guidedEvents";
import { isEqual } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";

const useGuidedEvent = (
    events: GuidedEvent,
    props: {
        disableForcedInitialLookup?: boolean;
    } = {
        disableForcedInitialLookup: false,
    }
) => {
    const { user } = useCurrentUser();

    const userAlerts = useUserAlertsSelector(state => state.userAlerts);
    const allStagedEvents = useUserAlertsSelector(state => state.allStagedEvents);

    const refreshUserAlerts = useUserAlertsSelector(state => state.refreshUserAlerts);
    const removeFromDone = useUserAlertsSelector(state => state.removeFromDone);
    const addTodoEvent = useUserAlertsSelector(state => state.addTodoEvent);
    const _closeEvent = useUserAlertsSelector(state => state.closeEvent);

    const decrementVisitCount = useUserAlertsSelector(state => state.decrementVisitCount);
    const removeFromTodo = useUserAlertsSelector(state => state.removeFromTodo);
    const setAllStagedEvents = useUserAlertsSelector(state => state.setAllStagedEvents);
    const commitUpdateUserAlerts = useUserAlertsSelector(state => state.commitUpdateUserAlerts);

    const closeEvent = useCallback(
        async (eventName: string) => {
            _closeEvent(eventName);
            await commitUpdateUserAlerts();
        },
        [_closeEvent, commitUpdateUserAlerts]
    );

    const [stagedEvents, setStagedEvents] = useState<GuidedEventObject[]>();

    const cachedUserAlerts = useRef(userAlerts);
    const errorsChecked = useRef(false);
    const initialLoadUpDone = useRef(false);
    const userAlertsRefreshed = useRef(false);
    const forcedInitialLookupForEventsDone = useRef(!!props?.disableForcedInitialLookup);

    const lookForEventsToShow = useCallback(() => {
        events?.forEach(event => {
            const visitCount = event.visitCount ?? 0;

            const alreadyExistsInDone = userAlerts?.done?.includes?.(event.eventName);
            const alreadyExistsInTodo = userAlerts?.todo?.map(event => event.eventName)?.includes?.(event.eventName);

            const hasUserProp = !!event.userProp;
            const hasPlatformProp = !!event.platform;

            setStagedEvents(prev =>
                prev?.filter(upcomingEvent => !userAlerts?.done?.includes?.(upcomingEvent.eventName))
            );

            setAllStagedEvents(prev => prev?.filter(eventName => !userAlerts?.done?.includes?.(eventName)));

            if (hasUserProp) {
                const shouldAddToDone = shouldSkipAlert({ user, event });

                if (shouldAddToDone) {
                    if (!alreadyExistsInDone) {
                        return closeEvent(event.eventName);
                    }

                    return;
                }
            }

            if (hasPlatformProp && event.platform !== platform.platformType) return;

            if (visitCount === 0 && !alreadyExistsInDone) {
                if (event.action === GuidedEventAction.REMOVE) return removeFromDone(event.eventName);
                return setStagedEvents(prev => [...new Set([...(prev ?? []), event])]);
            }

            if (visitCount > 0 && !alreadyExistsInTodo && !alreadyExistsInDone) {
                return addTodoEvent(event);
            }
        });
    }, [
        addTodoEvent,
        closeEvent,
        events,
        removeFromDone,
        setAllStagedEvents,
        user,
        userAlerts?.done,
        userAlerts?.todo,
    ]);

    useEffect(() => {
        if (!initialLoadUpDone.current && userAlerts) {
            initialLoadUpDone.current = true;
        }

        if (!errorsChecked.current) {
            const allEventNamesSet = new Set(allEventNamesList);
            const allEventNamesListHasDuplicates = allEventNamesSet.size !== allEventNamesList.length;
            if (allEventNamesListHasDuplicates) {
                const duplicateEvents = allEventNamesList.filter(
                    (eventName, index, self) => self.indexOf(eventName) !== index
                );

                throw new Error(`Duplicate event names found in GUIDED_EVENTS : ${duplicateEvents}`);
            }
            errorsChecked.current = true;
        }

        if (initialLoadUpDone.current && !userAlertsRefreshed.current) {
            const { eventsToStage, eventsToDecrementVisitCount, eventsToRemoveFromDone, eventsToRemoveFromTodo } =
                refreshUserAlerts({ events });

            eventsToStage.forEach(event => setStagedEvents(prev => [...new Set([...(prev ?? []), event])]));
            eventsToDecrementVisitCount.forEach(event => decrementVisitCount(event));
            eventsToRemoveFromDone.forEach(event => removeFromDone(event));
            eventsToRemoveFromTodo.forEach(event => removeFromTodo(event));

            userAlertsRefreshed.current = true;
        }

        if (
            initialLoadUpDone.current &&
            errorsChecked.current &&
            userAlertsRefreshed.current &&
            (!isEqual(cachedUserAlerts.current, userAlerts) || !forcedInitialLookupForEventsDone.current)
        ) {
            forcedInitialLookupForEventsDone.current = true;
            cachedUserAlerts.current = userAlerts;
            lookForEventsToShow();
        }

        const stagedEventsExistInAllStagedEvents = stagedEvents?.every(event =>
            allStagedEvents.includes(event.eventName)
        );

        if (stagedEvents?.length && !stagedEventsExistInAllStagedEvents) {
            const eventNames = stagedEvents?.map(event => event.eventName);

            setAllStagedEvents(prev => [...new Set([...(prev ?? []), ...(eventNames ?? [])])]);
        }
    }, [
        allStagedEvents,
        decrementVisitCount,
        errorsChecked,
        events,
        initialLoadUpDone,
        lookForEventsToShow,
        refreshUserAlerts,
        removeFromDone,
        removeFromTodo,
        setAllStagedEvents,
        stagedEvents,
        userAlerts,
        userAlertsRefreshed,
        forcedInitialLookupForEventsDone,
    ]);

    const closeLatestEvent = useCallback(() => {
        if (!stagedEvents.length) return;

        const latestEvent = stagedEvents[0];

        if (latestEvent) closeEvent(latestEvent.eventName);
    }, [closeEvent, stagedEvents]);

    return {
        stagedEvents,
        allStagedEvents,
        latestEvent: stagedEvents?.[0],
        commitUpdateUserAlerts,
        closeLatestEvent,
        closeEvent,
    };
};

export default useGuidedEvent;
