export enum UNUSUAL_CONVERSION_ACTION {
    SIGN_UP = "sign-up",
    CREATE_ACCOUNT = "create-account",
    START_FREE_TRIAL = "start-free-trial",
    START_PAID_TRIAL = "start-paid-trial",
}

export enum UNUSUAL_TEXT_KEY {
    LECTURE_NOTE_TAKER = "LECTURE_NOTE_TAKER",
    AI_TEACHER_TOOLS_MAIN = "AI_TEACHER_TOOLS_MAIN",
    AI_TEACHER_TOOL = "AI_TEACHER_TOOL",
    EXAMS = "EXAMS",
    EXAMS_PRACTICE_ROOM = "EXAMS_PRACTICE_ROOM",
}

export const UNUSUAL_TEXT: Record<UNUSUAL_TEXT_KEY, { TITLE: string; SUBTITLE: string }> = {
    LECTURE_NOTE_TAKER: {
        TITLE: "lecture-note-taker-title",
        SUBTITLE: "lecture-note-taker-subtitle",
    },
    AI_TEACHER_TOOLS_MAIN: {
        TITLE: "ai-teacher-tools-main-title",
        SUBTITLE: "ai-teacher-tools-main-subtitle",
    },
    // will be used for all tools, each tool will append the tool name
    AI_TEACHER_TOOL: {
        TITLE: "ai-teacher-tool-title",
        SUBTITLE: "ai-teacher-tool-subtitle",
    },
    // will be used for all exams, each exam will append the exam name
    EXAMS: {
        TITLE: "exams-title",
        SUBTITLE: "exams-subtitle",
    },
    EXAMS_PRACTICE_ROOM: {
        TITLE: "exams-practice-room-title",
        SUBTITLE: "exams-practice-room-subtitle",
    },
} as const;
