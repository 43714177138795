import { FlexColumn } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";

const InitialSetupContainer = ({ children }: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumn
            style={{
                position: "relative",
                padding: "5rem",
                borderRadius: "2rem",
                backgroundColor: themeColors.neutralWhite,
                maxWidth: "80rem",
                width: "100%",
                margin: "auto",
            }}>
            {children}
        </FlexColumn>
    );
};

export default InitialSetupContainer;
