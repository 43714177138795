import {
    authenticateEdLinkSSO,
    referOrganizationV2,
    selectSchool,
    trackOrganizationPaywall,
    updateOrgMembers,
    updateOrganization,
} from "@/graphql/mutations";
import { adminListOrgClasses, adminListOrgMembers, getOrganization, listAllOrganizations } from "@/graphql/queries";
import { ServerClientWithCookies, client, listData } from "@/utils/client/graphql";
import { scrapeEmptyFields } from "@/utils/dataCleaning";
import {
    AdminListOrgInput,
    AuthenticateEdLinkSSOInput,
    Class,
    OrgUserDetails,
    Organization,
    ReferOrganizationInput,
    SelectSchoolInput,
    UpdateOrgMemberInput,
    UpdateOrganizationInput,
    UpgradeEvent,
} from "@knowt/syncing/graphql/schema";

export const getSchoolIdsFromOrg = (org?: Organization | null) =>
    org?.schoolsSettings?.length ? org.schoolsSettings.map(s => s.schoolId as string) : [];

export const callReferOrganization = async ({ sent, type, email }: ReferOrganizationInput) => {
    return await client
        .mutate({
            mutation: referOrganizationV2,
            variables: {
                input: scrapeEmptyFields({
                    sent,
                    type,
                    email,
                }),
            },
        })
        .then(({ data }) => data.referOrganizationV2)
        .catch(() => {
            throw new Error("Organization not referred");
        });
};

export const callSelectSchool = async ({ schoolId, schoolRole }: SelectSchoolInput) => {
    return await client
        .mutate({
            mutation: selectSchool,
            variables: {
                input: scrapeEmptyFields({
                    schoolId,
                    schoolRole,
                }),
            },
        })
        .then(({ data }) => data.selectSchool)
        .catch(() => {
            throw new Error("School not selected");
        });
};

export const callTrackOrganizationPaywall = async ({ event }: { event: UpgradeEvent }) => {
    return await client
        .mutate({
            mutation: trackOrganizationPaywall,
            variables: {
                input: scrapeEmptyFields({
                    event,
                }),
            },
        })
        .then(({ data }) => data.trackOrganizationPaywall)
        .catch(() => {
            throw new Error("Organization paywall not tracked");
        });
};

export const callAuthenticateEdLinkSSO = async (input: AuthenticateEdLinkSSOInput) => {
    return await client
        .mutate({
            mutation: authenticateEdLinkSSO,
            variables: {
                input,
            },
        })
        .then(({ data }) => data.authenticateEdLinkSSO);
};

export const callAdminListOrgMembers = async ({
    org,
    serverClient,
}: AdminListOrgInput & {
    serverClient?: ServerClientWithCookies;
}) => {
    if (!org) return null;

    const members = (await listData({
        listQuery: adminListOrgMembers,
        input: { org },
        queryName: "adminListOrgMembers",
        serverClient,
    })) as OrgUserDetails[];

    return members;
};

export const callAdminListOrgClasses = async ({
    org,
    serverClient,
}: AdminListOrgInput & {
    serverClient?: ServerClientWithCookies;
}) => {
    if (!org) return null;

    return (await listData({
        listQuery: adminListOrgClasses,
        input: { org },
        queryName: "adminListOrgClasses",
        serverClient,
    })) as Class[];
};

export const callGetOrganization = async ({
    org,
    serverClient,
}: {
    org: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return await client
        .query({
            query: getOrganization,
            variables: {
                input: {
                    org,
                },
            },
            serverClient,
        })
        .then(({ data }) => data.getOrganization);
};

export const callListAllOrganizations = async ({ serverClient }: { serverClient?: ServerClientWithCookies } = {}) => {
    return (await listData({
        listQuery: listAllOrganizations,
        input: {},
        queryName: "listAllOrganizations",
        serverClient,
    })) as Organization[];
};

export const callUpdateOrganization = async (updatedOrganization: UpdateOrganizationInput) => {
    return await client
        .mutate({
            mutation: updateOrganization,
            variables: {
                input: updatedOrganization,
            },
        })
        .then(({ data }) => data.updateOrganization);
};

export const callUpdateOrgMembers = async ({
    org,
    udpatedMemberDetails,
}: {
    org: string;
    udpatedMemberDetails: UpdateOrgMemberInput[];
}) => {
    return await client
        .mutate({
            mutation: updateOrgMembers,
            variables: {
                input: {
                    org,
                    users: udpatedMemberDetails,
                },
            },
        })
        .then(({ data }) => data.updateOrgMembers.items);
};
