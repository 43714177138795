"use client";

import { styled } from "@mui/material/styles";
import { themeColors } from "@/utils/themeColors";

const DividerContainer = styled("div")`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    color: ${themeColors.neutral3};
    font-weight: 600;
`;

const DividerLine = styled("div")`
    flex: 1;
    height: 1px;
    margin: 0.95rem 0;
    background-color: ${themeColors.neutral1};
`;

const DividerText = styled("span")`
    opacity: 0;
    transition: opacity 200ms ease-out;
    
    &.visible {
        opacity: 1;
    }
`;

const SidebarDivider = ({ name, isExpanded }: { name?: string; isExpanded: boolean | null | undefined }) => {
    return (
        <DividerContainer className="secondaryTextBold1">
            {name && (
                <DividerText
                    className={name ? "visible" : ""}
                    style={{
                        marginLeft: isExpanded ? "1rem" : "0",
                        color: themeColors.neutral2,
                        opacity: isExpanded ? 1 : 0,
                        whiteSpace: "nowrap",
                    }}>
                    {name}
                </DividerText>
            )}
            {!isExpanded && <DividerLine />}
        </DividerContainer>
    );
};

export default SidebarDivider;
