import { callMigrateAccountContent } from "@/hooks/migrateAccountContent/graphqlUtils";
import { signOut } from "@/hooks/user/auth";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { platform } from "@/platform";
import { TIME, now } from "@/utils/dateTimeUtils";
import { useEffect, useState } from "react";

export const useStageSSOAccountLinking = () => {
    const { user } = useCurrentUser();

    const linkInitialAccount = async () => {
        const migKey = now().toString();
        const storage = await platform.storage();
        await storage.setWithExpiry("migKey", migKey, TIME.MINUTE * 5);
        await storage.setWithExpiry("ssoUserId", user?.ID, TIME.MINUTE * 5);
        await callMigrateAccountContent({ stage: true, migKey });

        await setTimeout(() => signOut(), 1_000);
    };

    const clearInitialAccount = async () => {
        const storage = await platform.storage();
        await storage.remove("migKey");
        await storage.remove("ssoUserId");
    };

    return { linkInitialAccount, clearInitialAccount };
};

export const useIsSSOLinkingOngoing = () => {
    const { user } = useCurrentUser();
    const [isSSOLinkingOngoing, setIsSSOLinkingOngoing] = useState<boolean>();

    useEffect(() => {
        (async () => {
            const storage = await platform.storage();
            const migKey = (await storage.getWithExpiry("migKey")) as string;
            const ssoUserId = (await storage.getWithExpiry("ssoUserId")) as string;
            setIsSSOLinkingOngoing(!!migKey && !!ssoUserId);
        })();
    }, [user]);

    return isSSOLinkingOngoing;
};

export const useCommitSSOAccountLinking = () => {
    const [isCommitting, setIsCommitting] = useState(true);

    const commitSSOAccountLinking = async () => {
        const storage = await platform.storage();

        await storage.remove("migKey");
        await storage.remove("ssoUserId");
        setIsCommitting(false);
    };

    return { commitSSOAccountLinking, isCommitting };
};
