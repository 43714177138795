"use client";
import { CircularButtonProps } from "@/components/CircularButton";
import CircularButton from "@/components/CircularButton/CircularButton";
import { themeColors } from "@/utils/themeColors";
import clsx from "clsx";
import { LucideIcon, LucideProps } from "lucide-react";
import SidebarIcon from "./SidebarIcon";
import Fade from "@mui/material/Fade";

export const SidebarItemBtn = ({
    isExpanded,
    label,
    Icon,
    IconProps,
    tooltip,
    sx,
    CustomIcon,
    ...divProps
}: CircularButtonProps & {
    isExpanded: boolean;
    label: string;
    tooltip?: string;
    href?: string;
    Icon?: LucideIcon;
    IconProps?: LucideProps;
    CustomIcon?: React.ReactNode;
}) => {
    return (
        <CircularButton
            tooltip={isExpanded ? undefined : label}
            TooltipProps={{ placement: "right" }}
            className={clsx("secondaryText1")}
            sx={{
                width: "100%",
                height: "3.2rem",
                borderRadius: "0.5rem",
                justifyContent: "flex-start",
                padding: "0.2rem 0.4rem",
                gap: "0.5rem",
                whiteSpace: "nowrap",
                position: "relative",
                "&:hover": {
                    backgroundColor: themeColors.neutral1,
                    color: themeColors.neutralBlack,
                    boxShadow: "none",
                    outline: "none",
                },
                textDecoration: "none",
                ...sx,
            }}
            {...divProps}>
            {CustomIcon && <SidebarIcon>{CustomIcon}</SidebarIcon>}
            {Icon && (
                <SidebarIcon>
                    <Icon {...IconProps} />
                </SidebarIcon>
            )}

            <Fade in={isExpanded} timeout={300}>
                <span>{label}</span>
            </Fade>
        </CircularButton>
    );
};
