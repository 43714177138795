import { fetchSchoolsById } from "./utils";
import { Organization, VerifiedSchool } from "@/graphql/schema";
import { useSWRImmutable } from "@/hooks/swr/immutable";
import { getSchoolIdsFromOrg } from "../organization/graphqlUtils";

export const resolveSchoolsSWRKey = ({ org }: { org?: string }) => org && (["schools", org] as const);

export const useSchool = (schoolId: string) => {
    const { data } = useSWRImmutable(
        schoolId ? ["school", schoolId] : null,
        async ([_, schoolId]) => (await fetchSchoolsById([schoolId]))?.[0] || null
    );

    return { school: data };
};

export const useSchools = ({
    organization,
    fallbackData,
}: {
    organization: Organization | undefined | null;
    fallbackData?: VerifiedSchool[];
}) => {
    const schoolIds = getSchoolIdsFromOrg(organization);

    const { data } = useSWRImmutable(
        schoolIds && resolveSchoolsSWRKey({ org: organization?.org }),
        async () => (await fetchSchoolsById(schoolIds)) || null,
        { fallbackData }
    );

    return { schools: data };
};

export const useSchoolName = (schoolId: string): string | null => {
    const school = useSchool(schoolId);
    if (typeof school === "undefined") return undefined;
    return school?.[0]?.name || null;
};

export const useSchoolType = (schoolId: string) => {
    const school = useSchool(schoolId);
    return school?.[0]?.type;
};
